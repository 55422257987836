import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: 'white',
        zIndex: 1
    },
    logoSection: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        paddingHorizontal: '3vw',
        justifyContent: 'flex-start',
        alignItems: 'center',
        zIndex: 10
    },
    menuCard: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: '#DDDDDD',
        paddingHorizontal: '1vw',
        paddingVertical: '1vh',
        columnGap: '1vw',
        marginRight: '2vw',
    },
    menuText: {
        fontFamily: "HaasGrotesk-Roman",
        fontSize: 13,
        fontStyle: "normal",
        letterSpacing: 0.65,
        color: "#292929",
        fontWeight: '400',
        color: '#292929'
    },
    searchCard: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: '#DDDDDD',
        paddingHorizontal: 12,
        paddingVertical: 9,
        columnGap: '1vw',
        marginRight: '2vw'
    },
    aboutUsCard: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: '1vw',
        paddingVertical: '1vh',
        columnGap: '1vw'
    },
    aboutUsText: {
        fontFamily: "HaasGrotesk-Medium",
        fontSize: 15,
        fontWeight: "500",
        fontStyle: "normal",
        letterSpacing: 1.05,
        color: "#292929"
    },
    tmcLogoCard: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: '1vw',
        paddingVertical: '1vh',
        columnGap: '1vw',
        alignSelf: 'center',
        width: '55vw',
        // flex: 1
    },
    tmcLogoText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        rowGap: 2
    },
    tmcLogoTextLine: {
        fontSize: 9
    },
    submitButtonCard: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: '1vw',
        paddingVertical: '1vh',
        columnGap: '1vw'
    },
    loginButtonCard: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: '1vw',
        paddingVertical: '1vh',
        columnGap: '1vw'
    },
    separator: {
        width: '100%',
        borderBottomWidth: 1,
        borderColor: '#DDDDDD',
    },
    headerSubSection: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: '1.5vw',
        marginVertical: '2vh'
    },
    headerSubSectionButtonText: {
        fontFamily: "HaasGrotesk-Medium",
        fontSize: 15,
        fontWeight: "500",
        fontStyle: "normal",
        lineHeight: 15,
        color: "#292929",
        alignSelf: 'center',
        letterSpacing: 1,
    },
    dropdown: {
        backgroundColor: '#FFFFFF',
        padding: 10,
        borderRadius: 5,
        marginVertical: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        elevation: 5, // For Android shadow,
        position: 'absolute',
        top: 25
    },
    dropdownItem: {
        color: "#292929",
        fontFamily: "HaasGrotesk-Medium",
        fontSize: 15,
        fontWeight: 500,
        width:150,
        padding: 10,
        letterSpacing:1
    },
    highlightedBackground: {
        backgroundColor: '#FFF3E3'
    }
})


export { styles }