import React, { Component } from 'react';
import { ActivityIndicator, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Cookies from "universal-cookie";
import { removeUserToken } from '../../../redux/User/user.actions';
import AppFooter from '../../components/app-footer';
import AppHeader from '../../components/app-header';
import AuthenticationWrapper from '../../components/authentication-wrapper';
import AuthorListCombo from '../../components/author-listing-combo';
import CustomImage from '../../components/custom-image';
import ErrorModal from '../../components/error-modal';
import Seo from "../../components/seo-meta";
import { appStyles, Colors, Flex, Helpers, Margin, pageContent, pageCover } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import getImagePath from '../../utils/image-helper';
import seoObj from "../../utils/seo-helper";
import Loader from '../../components/loader';

const upArrowIcon = getImagePath('icons/uparrow.png');

const crossIcon = getImagePath('icons/leadershipCross.png');
const circleIcon = getImagePath('icons/leadershipCircle.png');
const dualCircleIcon = getImagePath('icons/leadershipDualCircle.png');
const ashishPhoto = getImagePath('img/ashish-profile.jpg');
const priyaPhoto = getImagePath('img/priya-profile.jpg');

const cookies = new Cookies();

const directors = [
    {
        name: "Priya Bubna",
        photo: priyaPhoto,
        description: "Priya is the promoter of Slowform Holdings Ltd, the publisher of The Morning Context. She is a chartered accountant and a graduate of the Shri Ram College of Commerce. Priya specializes in international taxation and transfer pricing, where she has assisted clients on transfer pricing planning, complex advisories, litigation and representation across a broad range of industries. She has previously worked at EY and BMR Advisors."
    }
]

const editor = {
    name: "Ashish K. Mishra",
    photo: ashishPhoto,
    description: "Ashish is the editor-in-chief of The Morning Context. He is a graduate of the Shri Ram College of Commerce. One of the best-known business feature writers in India, Ashish was selected for Germany’s Robert Bosch Stiftung Fellowship in 2012 and received the Mumbai Press Club RedInk certificate of recognition in 2015 for his story “Inside the Network18 takeover”. Before joining The Morning Context, Ashish co-founded The Ken and led the editorial effort as managing editor. His previous writing stints include Mint, Forbes India and The Economic Times. Ashish writes long-form features across a broad range of subjects."
}
export class Leadership extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        const { isAuthenticated, fetchToken } = this.props.auth;
        let token = webView ? (process.browser ? (isAuthenticated ? fetchToken : false) : this.props.token) : this.props.user?.userData?.data?.token;
        this.state = {
            pageLoader: false,
            showErrorModal: false,
            showUparrow: false,
            footerData: this.props.footerData ? this.props.footerData : false,
            token: (token == undefined || token == '') ? false : token,
        }
    }

    componentDidMount = () => {
         window.location.href = window.location.origin + '/about-us'
    }

    renderPageHeader = () => {
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, helpers.isDesktop() && { paddingHorizontal: 20 }]}>
                <View style={[helpers.isDesktop() ? Margin.mb_7 : Margin.mb_5, Helpers.textCenter, { backgroundColor: darkMode ? Colors.darkBlackColor : '#FFF9F1', marginTop: helpers.isDesktop() ? 130 : 75, paddingHorizontal: helpers.isDesktop() ? 20 : 0, width: '100%' }, helpers.isDesktop() && { paddingHorizontal: 20 }]}>
                    <Text accessibilityRole="header" aria-level="1" style={[Margin.mt_0, Margin.mb_0, pageCover.title, helpers.isDesktop() && pageCover.titleDesktop, Helpers.textCenter, { position: 'relative', top: helpers.isDesktop() ? -42 : -25 }, !helpers.isWeb() && { top: -20 }, darkMode && { color: Colors.lightWhite }, !helpers.isWeb() && { top: -15 }]}>Leadership</Text>
                    <Text style={[{ marginBottom: 50 }, pageContent.subTitle, helpers.isDesktop() && pageContent.subTitleDesktop, { maxWidth: helpers.isDesktop() ? 900 : '100%', lineHeight: helpers.isDesktop() ? 40 : 30 }, !helpers.isWeb() && { textAlign: 'center' }, darkMode && { color: Colors.lightWhite }]}>Meet The Morning Context’s management team.</Text>
                    <CustomImage
                        source={dualCircleIcon}
                        require={true}
                        style={{ width: helpers.isDesktop() ? 46 : 30, height: helpers.isDesktop() ? 46 : 30, position: 'absolute', right: helpers.isDesktop() ? -30 : 10, top: helpers.isDesktop() ? 50 : 20, zIndex: -1 }}
                        webStyle={{ width: helpers.isDesktop() ? 46 : 30, height: helpers.isDesktop() ? 46 : 30, position: 'absolute', right: helpers.isDesktop() ? -20 : 10, top: helpers.isDesktop() ? 50 : 20, zIndex: -1 }}
                    />
                    {/* <CustomImage
                        source={crossIcon}
                        require={true}
                        style={{ width: helpers.isDesktop() ? 35 : 30, height: helpers.isDesktop() ? 35 : 30, position: 'absolute', left: helpers.isDesktop() ? -17 : 15, bottom: helpers.isDesktop() ? 126 : 75 }}
                        webStyle={{ width: helpers.isDesktop() ? 35 : 30, height: helpers.isDesktop() ? 35 : 30, position: 'absolute', left: helpers.isDesktop() ? -17 : 15, bottom: helpers.isDesktop() ? 126 : 75 }}
                    /> */}
                    <CustomImage
                        source={circleIcon}
                        require={true}
                        style={{ width: helpers.isDesktop() ? 50 : 30, height: helpers.isDesktop() ? 50 : 30, position: 'absolute', left: helpers.isDesktop() ? -25 : 15, bottom: helpers.isDesktop() ? 60 : 20 }}
                        webStyle={{ width: helpers.isDesktop() ? 50 : 30, height: helpers.isDesktop() ? 50 : 30, position: 'absolute', left: helpers.isDesktop() ? -20 : 15, bottom: helpers.isDesktop() ? 60 : 20 }}
                    />
                </View>
            </View>
        )
    }

    showPageLoader = () => {
        const webView = helpers.isWeb();
        return (
            <View style={[Flex.justfiyCenter, Flex.alignCenter,
            {
                position: webView ? 'fixed' : 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: '#ffffff',
            }]}
            >
                <ActivityIndicator size={25} color={'#000000'} />
            </View>
        )
    }

    renderPageContent = () => {
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <>
                <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, { marginBottom: helpers.isDesktop() ? 100 : 40 }]}>
                    <View style={[Margin.mb_4, Flex.row, !helpers.isDesktop() && Flex.justfiyCenter]}>
                        <View style={[!helpers.isDesktop() && { backgroundColor: darkMode ? Colors.darkBlackColorBg : 'white', zIndex: 1, paddingHorizontal: 20 }, helpers.isDesktop() && { paddingHorizontal: 20 }]}>
                            <View style={[Helpers.borderBottom, helpers.isDesktop() && { marginRight: 25 }, darkMode && { borderBottomColor: Colors.lineColor }]}>
                                <Text style={[pageContent.sectionTitle, helpers.isDesktop() && pageContent.sectionTitleDesktop, helpers.isDesktop() && { marginRight: 0 }, { paddingBottom: 5 }, darkMode && { color: Colors.lightWhite }]}>Board of Directors</Text>
                            </View>
                        </View>
                        {!helpers.isDesktop() && <View style={[Helpers.titleLine, darkMode && { backgroundColor: Colors.lineColor }]}></View>}
                        {helpers.isDesktop() && <View style={{ width: '100%', flex: 1, marginRight: 20 }}>
                            <View style={[Helpers.titleLineLight, darkMode && { backgroundColor: Colors.lineColor }]}></View>
                        </View>}
                    </View>
                    <View style={{ paddingHorizontal: helpers.isDesktop() ? 20 : 20 }}>
                        {
                            directors.map((director) => {
                                return <AuthorListCombo data={director} darkMode={darkMode} />
                            })
                        }
                    </View>
                </View>
                <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200, { marginBottom: helpers.isDesktop() ? 100 : 40 }]}>
                    <View style={[Margin.mb_4, Flex.row, !helpers.isDesktop() && Flex.justfiyCenter]}>
                        <View style={[!helpers.isDesktop() && { backgroundColor: darkMode ? Colors.darkBlackColorBg : 'white', zIndex: 1, paddingHorizontal: 20 }, helpers.isDesktop() && { paddingHorizontal: 20 }]}>
                            <View style={[Helpers.borderBottom, helpers.isDesktop() && { marginRight: 25 }, darkMode && { borderBottomColor: Colors.lineColor }, { marginBottom: 2 }]}>
                                <Text style={[pageContent.sectionTitle, helpers.isDesktop() && pageContent.sectionTitleDesktop, helpers.isDesktop() && { marginRight: 0 }, { paddingBottom: 5 }, darkMode && { color: Colors.lightWhite }]}> Editor in Chief </Text>
                            </View>
                        </View>
                        {!helpers.isDesktop() && <View style={[Helpers.titleLine, darkMode && { backgroundColor: Colors.lineColor }]}></View>}
                        {helpers.isDesktop() && <View style={{ width: '100%', flex: 1, marginRight: 20 }}>
                            <View style={[Helpers.titleLineLight, darkMode && { backgroundColor: Colors.lineColor }]}></View>
                        </View>}
                    </View>
                    <View style={{ paddingHorizontal: helpers.isDesktop() ? 20 : 20 }}>
                        <AuthorListCombo data={editor} darkMode={darkMode} />
                    </View>
                </View>
            </>
        )
    }

    renderWebPage = () => {
        return (
            <>
                {this.renderPageHeader()}
                {this.renderPageContent()}
            </>
        )
    }

    renderAppPage = () => {

        const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        const webView = helpers.isWeb();
        let redirectSlugs = {
            web: '/leadership',
            native: { redirect: '/leadership', verifyRedirect: '/leadership' }
        }
        return (
            <ScrollView>
                {this.renderPageHeader()}
                {this.renderPageContent()}
                <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} footerData={footerData} Breadcrumbs={Breadcrumbs} />
            </ScrollView>
        )
    }

    render() {
        const { pageLoader, showErrorModal, showUparrow, footerData } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        const webView = helpers.isWeb();
        let redirectSlugs = {
            web: '/leadership',
            native: { redirect: '/leadership', verifyRedirect: '/leadership' }
        }
        return (
            <Loader title={'loading about us...'} />
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationWrapper(Leadership));

let Breadcrumbs = [
    {
        name: 'Home',
        link: '/'
    },
    {
        name: 'Leadership'
    }
]