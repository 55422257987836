import { useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Arrow from "../../../../assets/icons/cards/down-arrow.svg";
import "./styles.module.css";

export default function RegionDropdown() {
  const [showDropdownValues, setShowDropdownValues] = useState(false);
  const location = useLocation();
  const isMiddleEast = location.pathname.includes("middle-east");

  const title = isMiddleEast ? "Middle East" : "India";
  const data = [
    { title: "India", value: "india" },
    { title: "Middle East", value: "middle-east" },
  ];
  const handleDropdownClick = (value) => {
    setShowDropdownValues(false);
    if (value === "middle-east") {
      window.location.href = "/middle-east";
    } else {
      window.location.href = "/";
    }
  };
  return (
    <div className="dropdown-container">
      <div
        className="dropdown-header"
        onClick={() => setShowDropdownValues(!showDropdownValues)}
      >
        <span
          className="dropdown-title"
          style={{ fontFamily: "HaasGrotesk-Medium" }}
        >
          {title} Edition
        </span>
        <img
          src={Arrow}
          alt="arrow-down"
          className={`arrow-icon ${showDropdownValues ? "rotate" : ""}`}
        />
      </div>
      {showDropdownValues ? (
        <div className="dropdown-list-container">
          <div className="dropdown-list">
            {data.map((item, index) => (
              <div
                key={index}
                className="dropdown-item"
                style={{ fontFamily: "HaasGrotesk-Medium" }}
                onClick={() => handleDropdownClick(item.value)}
              >
                <span>{item.title}</span>
                {item.title === title ? (
                  <span className="dropdown-tick">✓</span>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}
