import React, { Component } from "react";
import {
  View,
  ScrollView,
  Platform,
  TouchableOpacity,
  Text,
  StyleSheet,
} from "react-native";
import {
  Flex,
  Margin,
  Helpers,
  appStyles,
  FontFamily,
  button,
  article,
  Padding,
  pageContent,
  pageCover,
  Colors,
  articleLists,
  articleItemHorizontal,
  appHeader,
  FontWeight,
} from "../../styles/appStyles";
import { Utility } from "../../styles/custom.js";
import TouchableOpacityLink from "../touchable-opacity-link";
import TouchableOpacityLinkExternal from "../touchable-opacity-link-external";
import CustomImage from "../custom-image";
import getImagePath from "../../utils/image-helper";
import { helpers } from "../../utils/helpers";
import Button from "../button";
import ButtonGradient from "../button-gradient";
import ScrollContainerView from "../scroll-container-view";
import { CardView } from "react-native-simple-card-view";
import { relativeTimeRounding } from "moment";
import Swiper from "react-native-web-swiper";
import {
  renderBorderColor,
  categoryImage,
  paddingCategory,
} from "../../screens/yesterday/renderBorderColor";
import CommentIcon from '../../../../assets/img/comment.svg'

export class ThreeGridStory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seeSecondAuthor: [],
    };
  }
  componentDidMount = () => {
    let { latestNewsData, numberOfRow, sliceObj } = this.props;
    let slide_num_arr = [...Array(numberOfRow).keys()];
    let isAuthorEnable = [];
    let from = 0;
    let to = 0;
    for (let j = 0; j < slide_num_arr.length; j++) {
      from = sliceObj[j].from;
      to = sliceObj[j].to;
      isAuthorEnable[j] = [];
      let d = latestNewsData.slice(from, to);
      for (let i = 0; i < d.length; i++) {
        isAuthorEnable[j][i] = false;
      }
    }
    this.setState({ seeSecondAuthor: isAuthorEnable });
  };

  setSecondAuth = (row, index) => {
    let isAuthorEnable = [...this.state.seeSecondAuthor];
    isAuthorEnable[row][index] = true;
    this.setState({ seeSecondAuthor: isAuthorEnable });
  };

  
  pushNewsletterCategoryClickToDataLayer = (categoryName, categoryType) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "newsletter_category_click",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      newsletter_section: this.props.title || "NA",
      newsletter_category: categoryName,
      category_type: categoryType,
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  pushArticleCategoryClickToDataLayer = (categoryName, categoryType) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "article_category_click",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      article_section: this.props.title || "NA",
      article_category: categoryName,
      category_type: categoryType,
      scroll_depth: helpers.getScrollDepth(),
    });
  }

  render() {
    let webView = helpers.isWeb();
    let {
      darkMode,
      latestNewsData,
      viewArticle,
      viewAuthor,
      viewCategory,
      viewCompany,
      numberOfRow,
      sliceObj,
      title,
    } = this.props;
    let { seeSecondAuthor } = this.state;
    let slide_num_arr = [...Array(numberOfRow).keys()];

    return (
      <>
        {slide_num_arr &&
          slide_num_arr.map((d, i) => {
            let from = sliceObj[i].from;
            let to = sliceObj[i].to;
            return (
              <View
                style={{
                  flexDirection: helpers.isDesktop() ? "row" : "column",
                }}
              >
                {latestNewsData &&
                  latestNewsData.slice(from, to).map((item, index) => {
                    let btnClick,
                      authorBtnClick,
                      author2BtnClick;
                    if (helpers.isWeb()) {
                      btnClick = {
                        to: {
                          pathname: "/" + item.category.slug + "/" + item.slug,
                          postData: item,
                        },
                      };
                      authorBtnClick = {
                        to: "/writers/" + item.author[0].slug,
                      };
                      if (item.author[1]) {
                        author2BtnClick = {
                          to: "/writers/" + item.author[1].slug,
                        };
                      }
                    } else {
                      btnClick = {
                        onPress: () =>
                          viewArticle(item.category.slug, item.slug, item),
                      };
                      authorBtnClick = {
                        onPress: (event) => {
                          event.stopPropagation();
                          viewAuthor(item.author[0].slug);
                        },
                      };
                      if (item.author[1]) {
                        author2BtnClick = {
                          onPress: (event) => {
                            event.stopPropagation();
                            viewAuthor(item.author[1].slug);
                          },
                        };
                      }
                    }

                    const typesArray = item?.types?.map(item => {
                      return item.name.toLowerCase();
                    })
        
                    let btnCategoryClick = {
                      to:
                      title == "newsletter"
                        ? "/newsletters/" + item?.newsletter?.slug
                        : "/category/" + item.category.slug,
                      handleClick: item?.newsletter?.name
                        ? () =>
                            this.pushNewsletterCategoryClickToDataLayer(
                              item?.newsletter?.slug,
                              typesArray.join(",")
                            )
                        : () =>
                            this.pushArticleCategoryClickToDataLayer(
                              item?.category?.slug,
                              typesArray.join(",")
                            ),
                    };

                    return (
                      <View
                        style={{
                          paddingLeft: helpers.isDesktop() ? 16 : 20,
                          paddingRight: helpers.isDesktop() ? 16 : 20,
                          marginBottom: helpers.isDesktop() ? 0 : 42,
                          width: helpers.isDesktop() ? "33.33%" : "100%",
                        }}
                        key={`latestnewscol_${index}`}
                      >
                        <View style={helpers.isDesktop() && Margin.mb_3}>
                          {/* {helpers.isDesktop() ? ( */}
                          <TouchableOpacityLink {...btnCategoryClick}
                            activeOpacity={0.8}
                          >
                            <Text
                              style={[
                                {
                                  display: "block",
                                  marginBottom: helpers.isDesktop() ? 8 : 4,
                                  textAlign: "left",
                                  textTransform: "uppercase",
                                  fontFamily: FontFamily.regular,
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  color:
                                    item.category.name === "Business"
                                      ? "#2CB680"
                                      : item.category.name === "Chaos"
                                        ? "#907cff"
                                        : item.category.name === "Internet"
                                          ? "#FC876D"
                                          : "#ECB731",
                                  fontSize: helpers.isDesktop() ? 19 : 13,
                                  lineHeight: helpers.isDesktop() ? 22.8 : 16,
                                  letterSpacing: helpers.isDesktop() ? 1 : 0.5,
                                  fontFamily: "HaasGrotesk-Medium",
                                  fontWeight: 500,
                                },
                                !helpers.isWeb() && {
                                  fontFamily: FontFamily.regular,
                                },
                                darkMode && { color: "#5263F4" },
                              ]}
                            >
                              {/* {item.category.name} */}
                              {title == "newsletter"
                                ? item?.newsletter?.name
                                : item.category.name}
                            </Text>
                          </TouchableOpacityLink>
                          {/* ) : null} */}
                          {helpers.isDesktop() ? (
                            <TouchableOpacityLink {...btnClick}
                              activeOpacity={0.8}
                            >
                              <View
                                style={{
                                  marginBottom: helpers.isDesktop() ? 16 : 8,
                                }}
                              >
                                <CustomImage
                                  source={item?.featured_image?.url}
                                  require={false}
                                  style={{
                                    width: "100%",
                                    height: 243,
                                    objectFit: "cover",
                                  }}
                                  webStyle={{
                                    width: "100%",
                                    height: helpers.isDesktop() ? 243 : 200,
                                    objectFit: "cover",
                                  }}
                                  resizeMode={"cover"}
                                  altText={"mng"}
                                />
                              </View>
                            </TouchableOpacityLink>
                          ) : null}
                          <View>
                            <TouchableOpacityLink {...btnClick}
                              activeOpacity={0.8}
                            >
                              <Text
                                accessibilityRole="header"
                                aria-level={"3"}
                                style={[
                                  articleItemHorizontal.articleTitle,
                                  !helpers.isWeb() &&
                                  articleItemHorizontal.articleTitleApp,
                                  {
                                    fontWeight: "700",
                                    fontFamily: "PlayfairDisplay-Bold",
                                    fontSize: helpers.isDesktop() ? 24 : 16,
                                    lineHeight: helpers.isDesktop()
                                      ? 35.52
                                      : 22.4,
                                    marginBottom: helpers.isDesktop() ? 16 : 0,
                                  },
                                ]}
                                numberOfLines={2}
                              >
                                {item.title}
                              </Text>
                              {/* </View> */}
                            </TouchableOpacityLink>
                            {helpers.isDesktop() ? (
                              <TouchableOpacityLink{...btnClick}
                                activeOpacity={0.8}
                              >
                                <Text
                                  style={[
                                    // articleLists.badge,
                                    {
                                      // marginBottom: helpers.isDesktop() ? 15 : 10,
                                      textAlign: "left",
                                      fontFamily: FontFamily.regular,
                                      color: "#000",
                                      fontSize: helpers.isDesktop() ? 18 : 13,
                                      lineHeight: helpers.isDesktop() ? 27 : 18,
                                      letterSpacing: 0.3,
                                      // minHeight: helpers.isDesktop() ? 108 : 1,
                                    },
                                    // !helpers.isWeb() && { fontFamily: FontFamily.semiBold },
                                    darkMode && { color: "#5263F4" },
                                  ]}
                                  numberOfLines={3}
                                >
                                  {item.strapline}
                                </Text>
                              </TouchableOpacityLink>
                            ) : null}
                            <View
                              style={{
                                width: 100,
                                height: 1,
                                backgroundColor: "#907CFF",
                                marginTop: helpers.isDesktop() ? 16 : 4,
                                marginBottom: helpers.isDesktop() ? 18 : 6,
                              }}
                            ></View>

                            <View
                              style={[
                                Flex.row,
                                Flex.alignCenter,
                               Flex.justifyBetween
                              ]}
                            >
                              <View style={[
                                Flex.row,
                                Flex.alignCenter,
                              ]}>
                              <TouchableOpacityLink {...authorBtnClick}
                              >
                                <View
                                  style={[
                                    Flex.row,
                                    Flex.alignCenter,
                                    { marginRight: 15 },
                                  ]}
                                >
                                  <View
                                    style={{
                                      width: 34,
                                      height: 34,
                                      overflow: "hidden",
                                      marginRight: 10,
                                      borderRadius: "50%",
                                      padding: 1,
                                      borderStyle: "solid",
                                      borderWidth: 1,
                                      borderColor: "#FC876D",
                                    }}
                                  >
                                    <View
                                      style={{
                                        width: 30,
                                        height: 30,
                                        overflow: "hidden",
                                        borderRadius: "100%",
                                      }}
                                    >
                                      <CustomImage
                                        source={item.author[0].profile_image}
                                        require={true}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                        webStyle={{
                                          width: "100%",
                                          height: "auto",
                                        }}
                                      />
                                    </View>
                                  </View>
                                  <Text
                                    style={[
                                      {
                                        fontWeight: "400",
                                        fontSize: helpers.isDesktop() ? 16 : 16,
                                        lineHeight: helpers.isDesktop()
                                          ? 19
                                          : 22,
                                      },
                                      darkMode && {
                                        color: "rgba(255,255,255,0.87)",
                                      },
                                      { fontFamily: FontFamily.regular },
                                    ]}
                                  >
                                    {item.author[0].name}
                                  </Text>
                                </View>
                              </TouchableOpacityLink>
                              {seeSecondAuthor[i]?.[index] && item.author[1] && (
                                <TouchableOpacityLink {...author2BtnClick}
                                >
                                  <View
                                    style={[
                                      Flex.row,
                                      Flex.alignCenter,
                                      { marginRight: 15 },
                                    ]}
                                  >
                                    <View
                                      style={{
                                        width: 34,
                                        height: 34,
                                        overflow: "hidden",
                                        marginRight: 12,
                                        borderRadius: "50%",
                                        padding: 1,
                                        borderStyle: "solid",
                                        borderWidth: "0.45px",
                                        borderColor: "#FC876D",
                                      }}
                                    >
                                      <View
                                        style={{
                                          width: 30,
                                          height: 30,
                                          overflow: "hidden",
                                          borderRadius: "100%",
                                        }}
                                      >
                                        <CustomImage
                                          source={item.author[1].profile_image}
                                          require={true}
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                          }}
                                          webStyle={{
                                            width: "100%",
                                            height: "auto",
                                          }}
                                        />
                                      </View>
                                    </View>
                                    <Text
                                      style={[
                                        {
                                          fontWeight: "500",
                                          fontSize: helpers.isDesktop()
                                            ? 16
                                            : 14,
                                          lineHeight: helpers.isDesktop()
                                            ? 19
                                            : 16.8,
                                        },
                                        darkMode && {
                                          color: "rgba(255,255,255,0.87)",
                                        },
                                        { fontFamily: FontFamily.regular },
                                      ]}
                                    >
                                      {item.author[1].name}
                                    </Text>
                                  </View>
                                </TouchableOpacityLink>
                              )}
                              {item.author.length > 1 &&
                                !seeSecondAuthor[i]?.[index] && (
                                  <TouchableOpacity
                                    onPress={() => {
                                      this.setSecondAuth(i, index);
                                    }}
                                  >
                                    <View
                                      style={[
                                        {
                                          borderStyle: "solid",
                                          fontFamily: FontFamily.regular,
                                          fontSize: 16,
                                          lineHeight: 19,
                                          borderWidth: 1,
                                          backgroundColor: "#F3F5FF",
                                          borderColor: "#907CFF",
                                          width: 24,
                                          height: 24,
                                          borderRadius: "50%",
                                        },
                                        Flex.alignCenter,
                                        Flex.row,
                                        Flex.justfiyCenter,
                                      ]}
                                    >
                                      +1
                                    </View>
                                  </TouchableOpacity>
                                )}
                              </View>
                              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4, cursor: 'pointer'}} onClick={() => window.open(`${window.location.origin}/${item.category.slug}/${item.slug}?showComment=true`, '_self')}>
                                    <img src={CommentIcon} alt="comment" style={{width: 24, height: 24}} />
                                    <p>{item?.comment_count}</p>
                                </div>
                            </View>
                          </View>
                        </View>
                      </View>
                    );
                  })}
              </View>
            );
          })}
      </>
    );
  }
}
const styles = StyleSheet.create({
  miniCardStyle: {
    backgroundColor: "#ffffff",
    paddingHorizontal: "20px",
    borderRadius: 0,
    elevation: 3,
    width: 295,
  },
});
export default ThreeGridStory;
