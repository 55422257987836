import React, { Component } from "react";
import { Text, View, Image, ScrollView, Dimensions } from "react-native";
import {
  postSingle,
  Flex,
  Padding,
  Margin,
  Helpers,
  Typo,
  featuredArticle,
  FontFamily,
} from "../../styles/appStyles";
import TouchableOpacityLink from "../touchable-opacity-link";
import CustomImage from "../custom-image";
import moment from "moment";
import getImagePath from "../../utils/image-helper";
import { helpers } from "../../utils/helpers";
import PremiumTag from "../premium-tag";

const featureImage = getImagePath("img/featured.png");
const authorImage = getImagePath("img/userpic.png"); // temp

export class FeaturedArticle extends Component {
  constructor(props) {
    super(props);
    this.isUserSubscriptionActive = localStorage.getItem(
      "isUserSubscriptionActive"
    );
    this.userSubscription = localStorage.getItem("userSubscription");
    this.state = {};
  }

  componentDidMount = () => { };

  trackStoryClick = () => {
    if (this.props.trackStoryClick) {
      this.props.trackStoryClick(this.props.data.title);
    }
  };

  render() {
    const {
      data,
      category = {slug: 'yesterday'},
      viewArticle,
      viewAuthor,
      lazyLoad,
      writers,
      singleNewsletter,
      darkMode,
      Premium,
    } = this.props;
    const isExplainerStory = data && data.types && data.types.length > 0 && data.types[0].slug === 'yesterday';
    const path = `/${isExplainerStory ? 'yesterday' : data.category.slug}/${data.slug}`;
    let btnClick, authorBtnClick, authorBtn2Click;
    let checkDesktop = this.props.hideImageSection
      ? false
      : helpers.isDesktop()
        ? true
        : false;
    let checkDesktopWriters = this.props.hideItems
      ? writers
      : helpers.isDesktop()
        ? false
        : true;
    if (helpers.isWeb()) {
      btnClick = {
        to: {
          pathname: path,
          postData: { category, ...data },
        },
      };
      authorBtnClick = {
        to: data.author?.length ? "/writers/" + data.author[0].slug : data.authors[0].slug,
      };
      if (data.author?.length && data.author[1]) {
        authorBtn2Click = {
          to: "/writers/" + data.author[1].slug,
        };
      }else if(data.authors?.length && data.authors[1]){
        authorBtn2Click = {
          to: "/writers/" + data.authors[1].slug,
        };
      }
    } else {
      btnClick = {
        onPress: () =>
          viewArticle(category.slug, data.slug, { category, ...data }),
      };
      authorBtnClick = {
        onPress: (event) => {
          event.stopPropagation();
          viewAuthor(data.author ? data.author[0].slug : data.authors[0].slug);
        },
      };
      if (data.author && data.author[1]) {
        authorBtn2Click = {
          onPress: (event) => {
            event.stopPropagation();
            viewAuthor(data.author[1].slug);
          },
        };
      }else if(data.authors && data.authors[1]){
        authorBtn2Click = {
          onPress: (event) => {
            event.stopPropagation();
            viewAuthor(data.author[1].slug);
          },
        };
      }
    }
    return (
      <View
        style={[
          Padding.ph_2,
          Helpers.conatinerWeb,
          writers && helpers.isDesktop() && Helpers.conatinerWeb1200,
          !writers && helpers.isDesktop() && Helpers.conatinerWebFull,
          !helpers.isDesktop() &&
            (this.props.isShowBanner ? Margin.mb_0 : helpers.isDesktop() && Margin.mb_4 || null),
        ]}
      >
        {/* <View style={[Flex.alignCenter, Margin.mb_7, !helpers.isDesktop() && Margin.mb_4]}>
                    <View style={[Padding.ph_2, Helpers.titleBgWrap]}>
                        <View style={[Helpers.borderBottom]}>
                            <Text style={[Helpers.textCenter, Helpers.textUppercase, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, {fontSize: 18}, {fontSize: helpers.isDesktop() ? 30 : 18} ]}>Featured Story</Text>
                        </View>
                    </View>
                    <View style={[Helpers.titleLine]}></View>
                </View> */}
        <TouchableOpacityLink
          {...btnClick}
          handleClick={() => {
            this.trackStoryClick();
          }}
        >
          <View
            style={[
              helpers.isDesktop() && Margin.mt_4,
              !helpers.isDesktop() && Margin.mb_2,
              helpers.isDesktop() && [Flex.row, Flex.FlexWrap],
            ]}
          >
            <View style={helpers.isDesktop() && { width: "55%" }}>
              <CustomImage
                source={data.featured_image.url ? data.featured_image.url : data.featured_image}
                require={false}
                style={{
                  width: "100%",
                  height: 250,
                  alignSelf: "center",
                }}
                webStyle={{
                  width: "100%",
                  alignSelf: "center",
                  height: helpers.isDesktop() ? 441 : 250,
                  objectFit: "cover",
                }}
                resizeMode={"cover"}
                altText={
                  data.featured_image.alt && data.featured_image.alt != ""
                    ? data.featured_image.alt
                    : data.title
                    ? data.title
                    : data.story?.title
                    ? data.story.title
                    : data.featured_image.title
                }
                lazyLoad={lazyLoad ? true : false}
              />
              {/* {this.isUserSubscriptionActive == "0" ||
              this.isUserSubscriptionActive == null ? (
                <>{!data.is_free && <PremiumTag />}</>
              ) : null} */}
              {/* {
                        Premium&&  ( (  Premium.newsletter?.slug=='one-less-thing-to-worry-about'||Premium.newsletter?.slug=='friction'|| Premium.newsletter?.slug=='things-change'||Premium.newsletter?.slug=='oversize')&&
                            <View style={{ position: 'absolute', top: helpers.isDesktop()? 10:20,  right: 0, width:helpers.isDesktop()?'20%':'40%',height:25, backgroundColor: '#0077C8' }}>
<Text style={{fontFamily: FontFamily.abrilFatface,color:'#ffffff',textAlign:'center',letterSpacing:1}}>Premium</Text>
                            </View>)
                        } */}
            </View>
            {writers && !helpers.isDesktop() && (
              <Text
                style={[
                  Margin.mt_2,
                  featuredArticle.postDate,
                  helpers.isDesktop() && featuredArticle.postDateDesktop,
                  darkMode && { color: "rgba(255,255,255,0.6)" },
                ]}
              >
                {moment(data.published_date).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
              </Text>
            )}
            <View
              style={[
                Margin.mt_15,
                helpers.isDesktop() && {
                  width: "45%",
                  paddingLeft: 40,
                  marginTop: 0,
                },
              ]}
            >
              <View style={[Margin.mt_0]}>
                <Text
                  style={[
                    featuredArticle.title,
                    helpers.isDesktop() && featuredArticle.titleDesktop,
                    writers &&
                      !helpers.isDesktop() && {
                        fontFamily: "PlayfairDisplay-Medium",
                        letterSpacing: 0.3,
                        fontWeight: "600",
                        fontSize: 30,
                        lineHeight: 42,
                      },
                    category &&
                      !helpers.isDesktop() && {
                        textAlign: "left",
                        lineHeight: 35,
                      },
                    !helpers.isWeb() && { fontFamily: "PlayfairDisplay-Bold" },
                    darkMode && { color: "rgba(255,255,255,0.87)" },
                    { fontFamily: FontFamily.abrilFatface, fontWeight: "600" },
                  ]}
                >
                  {data.title}
                </Text>
                {!singleNewsletter ? (
                  <Text
                    style={[
                      featuredArticle.excerpt,
                      helpers.isDesktop() && featuredArticle.excerptDesktop,
                      writers && helpers.isDesktop() && { marginBottom: 50 },
                      checkDesktopWriters && { paddingBottom: 15 },
                      category &&
                        !helpers.isDesktop() && {
                          textAlign: "left",
                          fontSize: 16,
                        },
                      singleNewsletter && { color: "#292929" },
                      darkMode && { color: "rgba(255,255,255,0.87)" },
                    ]}
                  >
                    {data.strapline ? data.strapline : data.excerpt}
                  </Text>
                ) : (
                  <Text
                    style={[
                      featuredArticle.excerpt,
                      helpers.isDesktop() && featuredArticle.excerptDesktop,
                      writers && helpers.isDesktop() && { marginBottom: 50 },
                      category &&
                        !helpers.isDesktop() && {
                          textAlign: "left",
                          fontSize: 16,
                        },
                      singleNewsletter && { color: "#292929" },
                      darkMode && { color: "rgba(255,255,255,0.87)" },
                    ]}
                    numberOfLines={5}
                  >
                    {data.excerpt}
                  </Text>
                )}
                {!checkDesktopWriters && (
                  <View
                    style={[
                      featuredArticle.lineBreak,
                      Margin.mt_15,
                      { marginBottom: 18 },
                      !helpers.isDesktop() && {
                        marginRight: "auto",
                        marginLeft: "auto",
                      },
                    ]}
                  ></View>
                )}
                {category && !helpers.isDesktop() && (
                  <View
                    style={[
                      featuredArticle.lineBreak,
                      Margin.mt_15,
                      { marginBottom: 18 },
                      !helpers.isDesktop() && { marginRight: "auto" },
                    ]}
                  ></View>
                )}
              </View>
              <View>
                <View
                  style={[
                    postSingle.authorDetailsWrap,
                    Flex.alignCenter,
                    helpers.isDesktop() && Flex.justfiyCenter,
                    helpers.isDesktop() && Flex.justifyStart,
                    Flex.FlexWrap,
                  ]}
                >
                  {!singleNewsletter && (
                    <TouchableOpacityLink {...authorBtnClick}>
                      <View
                        style={[
                          postSingle.authorDetailsWrap,
                          helpers.isDesktop() && Flex.alignCenter,
                          Flex.justfiyCenter,
                          helpers.isDesktop() && Flex.justifyStart,
                        ]}
                      >
                        <View
                          style={[
                            postSingle.authorPicWrap,
                            { width: 35, height: 35 },
                          ]}
                        >
                          <CustomImage
                            source={data.author?.length ? data.author[0].profile_image : data.authors[0].profile_image}
                            require={false}
                            webStyle={{
                              width: 31,
                              height: 31,
                              borderRadius: 100,
                            }}
                            resizeMode={"contain"}
                            style={{ width: 31, height: 31, borderRadius: 100 }}
                            altText={data.author?.length ? data.author[0].name : data.authors[0].name}
                          />
                        </View>
                        <View style={[Padding.pl_1]}>
                          <Text
                            style={[
                              postSingle.authorName,
                              helpers.isDesktop() &&
                                postSingle.authorNameDesktop,
                              category &&
                                !helpers.isDesktop() && { fontSize: 15 },
                              darkMode && { color: "rgba(255,255,255,0.87)" },
                            ]}
                          >
                            {data.author?.length ? data.author[0].name : data.authors?.length ? data?.authors[0].name : ""}
                          </Text>
                        </View>
                      </View>
                    </TouchableOpacityLink>
                  )}
                  {!singleNewsletter && ((data.author?.length && data.author[1]) || (data.authors?.length && data.authors[1])) && (
                    <>
                      <View
                        style={[
                          featuredArticle.dotSeprator,
                          { marginBottom: 0 },
                        ]}
                      />
                      <TouchableOpacityLink {...authorBtn2Click}>
                        <View
                          style={[
                            postSingle.authorDetailsWrap,
                            Flex.alignCenter,
                            Flex.justfiyCenter,
                            helpers.isDesktop() && Flex.justifyStart,
                          ]}
                        >
                          <View
                            style={[
                              postSingle.authorPicWrap,
                              { width: 35, height: 35 },
                            ]}
                          >
                            <CustomImage
                              source={data.author ? data.author[1].profile_image: data.authors[1].profile_image}
                              require={false}
                              webStyle={{
                                width: 31,
                                height: 31,
                                borderRadius: 100,
                              }}
                              resizeMode={"contain"}
                              style={{
                                width: 31,
                                height: 31,
                                borderRadius: 100,
                              }}
                              altText={data.author ? data.author[1].name : data.authors[1].name}
                            />
                          </View>
                          <View style={[Padding.pl_1]}>
                            <Text
                              style={[
                                postSingle.authorName,
                                helpers.isDesktop() &&
                                  postSingle.authorNameDesktop,
                                category &&
                                  !helpers.isDesktop() && { fontSize: 15 },
                                darkMode && { color: "rgba(255,255,255,0.87)" },
                              ]}
                            >
                              {data.author ? data.author[1].name : data.authors[1].name}
                            </Text>
                          </View>
                        </View>
                      </TouchableOpacityLink>
                    </>
                  )}
                  {writers && helpers.isDesktop() && (
                    <View
                      style={[featuredArticle.dotSeprator, { marginTop: 0 }]}
                    />
                  )}
                  {writers && helpers.isDesktop() && (
                    <Text
                      style={[
                        featuredArticle.postDate,
                        helpers.isDesktop() && featuredArticle.postDateDesktop,
                        Padding.pv_1,
                        darkMode && { color: "rgba(255,255,255,0.6)" },
                      ]}
                    >
                      {moment(data.published_date).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
                    </Text>
                  )}
                  {!writers && !singleNewsletter && (
                    <View
                      style={[featuredArticle.dotSeprator, { marginTop: 0 }]}
                    />
                  )}
                  {!writers && (
                    <Text
                      style={[
                        featuredArticle.postDate,
                        helpers.isDesktop() && featuredArticle.postDateDesktop,
                        Padding.pv_1,
                        singleNewsletter && { color: "#292929" },
                        darkMode && { color: "rgba(255,255,255,0.6)" },
                      ]}
                    >
                      {moment(data.published_date).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
                    </Text>
                  )}
                </View>
              </View>
            </View>
          </View>
        </TouchableOpacityLink>
      </View>
    );
  }
}

export default FeaturedArticle;
