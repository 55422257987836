import HomeScreen from '../../web/home-screen';
import PostSingleScreen from '../../web/post-single';
import SignUpScreen from '../../web/sign-up';
import SignUpScreenInstitution from '../../web/sign-up-institution';
import SignInInstitutionScreen from '../../web/sign-in-institution';
import SignInScreen from '../../web/sign-in';
import VerifyEmailScreen from '../../web/verify-email';
import VerifyEmailScreenIp from '../../web/verify-email-ip';
import SignUpSuccessScreen from '../../web/sign-up-success';
import SignUpSuccessScreenIp from '../../web/sign-up-success-ip';
import CategoryScreen from '../../web/category-screen';
import WritersScreen from '../../web/writers-screen';
import WriterSingleScreen from '../../web/writer-single-screen';
import CompanyScreen from '../../web/company-screen';
import UserInterestsScreen from '../../web/user-interests-screen';
import SubscriptionScreen from '../../web/subscription-screen';
import PaymentRedirect from '../../web/payment-redirect';
import AboutScreen from '../../web/about-screen';
import ArchiveScreen from '../../web/archive-screen';
import ArchiveLongreads from '../../web/archive-longreads';
import Shorts from '../../web/shorts';
import ArchiveScreenNewsletters from '../../web/archive-newsletters';
import InvestorsScreen from '../../web/investors-screen';
import TeamScreen from '../../web/team-screen';
import MyTeamScreen from '../../web/my-team-screen';
import UnlockCollectionScreen from '../../web/unlock-collection-screen';
import NewsletterScreen from '../../web/newsletter-screen';
import TermsAndConditionsScreen from '../../web/terms-and-conditions-screen';
import ComingSoon from '../../web/coming-soon';
import ReadingListScreen from '../../web/reading-list-screen';
import SingleNewsletterScreen from '../../web/single-newsletter-screen';
import PrivacyPolicyScreen from '../../web/privacy-policy-screen';
import EditorialCodeScreen from '../../web/editorial-code-of-conduct-screen';
import FaqScreen from '../../web/faq-screen';
import LeadershipScreen from '../../web/leadership-screen';
import ForgotPasswordScreen from '../../web/forgot-password-screen';
import ResetPasswordScreen from '../../web/reset-password-screen';
import BlogScreen from '../../web/blog-screen';
import BlogSingleScreen from '../../web/blog-single-screen';
import DynamicPageScreen from '../../web/dynamic-page-screen';
import UnlockedStoriesScreen from '../../web/unlocked-stories-screen';
import CookiesInfoScreen from '../../web/cookies-info-screen';
import TeamSubscriptionScreen from '../../web/team-subscription-screen';
import VerifyMemberInviteScreen from '../../web/verify-member-invite';
import NoSubscriptionPlanScreen from '../../web/no-subscription-plan-screen';
import StudentSubscriptionScreen from '../../web/student-subscription-screen';
import DocumentApprovalScreen from '../../web/document-approval-screen';
import AdminViewUserProfileScreen from '../../web/admin-view-user-profile-screen';
import ClaimGiftScreen from '../../web/claim-gift-screen';
import GiftingScreen from '../../web/gifting-screen';
import DownloadApp from '../../web/app-download';
import LastWeekScreen from '../../web/last-week-screen';
import Yesterdays from '../../web/yesterday-screen';
import YesterdayStoryPage from '../../web/yesterday-story-page-screen';
import LogoutUserSession from '../../web/oauth2-logout';
import ClaimSub  from '../../web/claim-sub';
import ClaimSubError from '../../web/claim-sub-error';
import WhoReadsUs from '../../web/who-reads-us';
import GrievanceReports from '../../web/grievance-report';
import { GrievanceReportScreen } from '../../web/grievance-redressal-screen';



export const routes = [
    {
        path: '/',
        component: HomeScreen,
        type: 'home',
        serverApi: true
    },

    {
        path: '/logout',
        component: LogoutUserSession,
        type: 'user-logout',
        serverApi: false

    },

    {
        path: '/payment-redirect',
        component: PaymentRedirect,
        type: 'payment-redirect',
        serverApi: false,
        pageTitle: 'Payment Success'
    },
    {
        path: '/company',
        component: CompanyScreen,
        type: 'company',
        serverApi: true
    },
    {
        path: '/category/:category_slug',
        component: CategoryScreen,
        type: 'category',
        serverApi: true
    },
    {
        path: '/writers/:writer_slug',
        component: WriterSingleScreen,
        type: 'writer-single',
        serverApi: true
    },
    {
        path: '/sign-up',
        component: SignUpScreen,
        type: 'signup',
        serverApi: false,
        pageTitle: 'Sign Up'
    },
    {
        path: '/sign-up-institution',
        component: SignUpScreenInstitution,
        type: 'signup-institution',
        serverApi: false
    },
    {
        path: '/sign-in',
        component: SignInScreen,
        type: 'signin',
        serverApi: false,
        pageTitle: 'Sign Up'
    },
    {
        path: '/sign-in-institution',
        component: SignInInstitutionScreen,
        type: 'signininstitution',
        serverApi: false
    },
    {
        path: '/verify-email',
        component: VerifyEmailScreen,
        type: 'verifyemail',
        serverApi: false,
        pageTitle: 'Verify Email'
    },
    {
        path: '/verify-email-ip',
        component: VerifyEmailScreenIp,
        type: 'verifyemail',
        serverApi: false
    },
    {
        path: '/verify-change-email',
        component: VerifyEmailScreen,
        type: 'verifyemail',
        serverApi: false,
        params: { type: 'email-change' },
        pageTitle: 'Verify Email'
    },
    {
        path: '/sign-up-success',
        component: SignUpSuccessScreen,
        type: 'signupsuccess',
        serverApi: false,
        pageTitle: 'Sign Up Success'
    },
    {
        path: '/sign-up-success-institution',
        component: SignUpSuccessScreenIp,
        type: 'signupsuccess',
        serverApi: false
    },
    {
        path: '/save-interests',
        component: UserInterestsScreen,
        type: 'interests',
        serverApi: false,
        params: { initialSave: true },
        pageTitle: 'User Interests'
    },
    {
        path: '/pricing',
        component: SubscriptionScreen,
        type: 'subscriptions',
        serverApi: false
    },
    {
        path: '/pricing/:subscriptionType',
        component: SubscriptionScreen,
        type: 'subscriptions',
        serverApi: false
    },
    {
        path: '/pricing:advance_renew',
        component: SubscriptionScreen,
        type: 'subscriptions',
        serverApi: false
    },
    {
        path: '/about-us',
        component: AboutScreen,
        type: 'about',
        serverApi: true
    },
    {
        path: '/category/:archive_slug/all',
        component: ArchiveScreen,
        type: 'archive',
        archiveType: 'category',
        serverApi: true,
        params: { archiveType: 'category' }
    },
    {
        path: '/category/:archive_slug/archive',
        component: ArchiveScreen,
        type: 'archive',
        archiveType: 'category-archive',
        serverApi: true,
        params: { archiveType: 'category-archive' }
    },
    {
        path: '/company/:archive_slug',
        component: ArchiveScreen,
        type: 'archive',
        archiveType: 'company',
        serverApi: true,
        params: { archiveType: 'company' }
    },
    {
        path: '/tag/:archive_slug',
        component: ArchiveScreen,
        type: 'archive',
        archiveType: 'tag',
        serverApi: true,
        params: { archiveType: 'tag' }
    },
    {
        path: '/theme/:archive_slug',
        component: ArchiveScreen,
        type: 'archive',
        archiveType: 'theme',
        serverApi: true,
        params: { archiveType: 'theme' }
    },
    {
        path: '/all-stories',
        component: ArchiveScreen,
        type: 'archive',
        archiveType: 'all',
        serverApi: true,
        params: { archiveType: 'all' }
    },
    {
        path: '/longreads',
        component: ArchiveLongreads,
        type: 'archive',
        archiveType: 'all',
        serverApi: true,
        params: { archiveType: 'all' }
    },
    {
        path: '/news-briefs',
        component: Shorts,
        type: 'archive',
        archiveType: 'all',
        serverApi: true,
        params: { archiveType: 'all' }
    },
    {
        path: '/newsletters-for-you',
        component: ArchiveScreenNewsletters,
        type: 'archive',
        archiveType: 'all',
        serverApi: true,
        params: { archiveType: 'all' }
    },
    {
        path: '/archive',
        component: ArchiveScreen,
        type: 'archive',
        archiveType: 'archive',
        serverApi: true,
        params: { archiveType: 'archive' }
    },
    {
        path: '/last-week',
        component: LastWeekScreen,
        type: 'archive',
        serverApi: true,
    },
    // {
    //     path: '/investors',
    //     component: InvestorsScreen,
    //     type: 'investors',
    //     serverApi: true
    // },
    {
        path: '/free-reads',
        type: 'startercollection',
        component: UnlockCollectionScreen,
        serverApi: false,
        params: { collectionType: 'starter' }
    },
    {
        path: '/premium-collection',
        type: 'premiumcollection',
        component: UnlockCollectionScreen,
        serverApi: false,
        params: { collectionType: 'premium' }
    },
    {
        path: '/newsletters',
        component: NewsletterScreen,
        type: 'newsletters',
        serverApi: true
    },
    {
        path: '/terms-and-conditions',
        component: TermsAndConditionsScreen,
        serverApi: false,
        type: 'terms-and-conditions',
        pageTitle: 'Terms And Conditions'
    },
    {
        path: '/bookmarks',
        component: ReadingListScreen,
        serverApi: false,
        type: 'bookmarks'
    },
    {
        path: '/writers',
        component: WritersScreen,
        type: 'writers',
        serverApi: true
    },
    {
        path: '/team',
        component: TeamScreen,
        type: 'team',
        serverApi: true
    },
    {
        path: '/leadership',
        component: LeadershipScreen,
        serverApi: false,
        type: 'leadership'
    },

    {
        path: '/privacy-policy',
        component: PrivacyPolicyScreen,
        serverApi: false,
        type: 'privacy-policy',
        pageTitle: 'Privacy Policy'
    },
    {
        path: '/faq',
        component: FaqScreen,
        serverApi: false,
        type: 'faq',
        pageTitle: 'FAQ'
    },
    {
        path: '/editorial-code-of-conduct',
        component: EditorialCodeScreen,
        serverApi: false,
        type: 'editorial-code-of-conduct'
    },

    {
        path: '/newsletters/:letter_slug',
        component: SingleNewsletterScreen,
        type: 'newsletter-details',
        serverApi: true
    },
    {
        path: '/forgot-password',
        component: ForgotPasswordScreen,
        type: 'forgotpassword',
        serverApi: false,
        pageTitle: 'Forgot Password'
    },
    {
        path: '/reset-password',
        component: ResetPasswordScreen,
        type: 'resetpassword',
        serverApi: false,
        pageTitle: 'Reset Password'
    },
    {
        path: '/blog',
        component: BlogScreen,
        serverApi: true,
        type: 'blog'
    },
    {
        path: '/blog/:blog_slug',
        component: BlogSingleScreen,
        type: 'blog-single',
        serverApi: true
    },
    {
        path: '/unlocked-stories',
        component: UnlockedStoriesScreen,
        serverApi: false,
        params: { unlockType: 'credit' },
        type: 'unlocked-stories'
    },
    {
        path: '/free-reads',
        component: UnlockedStoriesScreen,
        serverApi: false,
        params: { unlockType: 'free' },
        type: 'free-reads'
    },
    {
        path: '/enable-third-party-cookies',
        component: CookiesInfoScreen,
        serverApi: false,
        type: 'enable-third-party-cookies',
        pageTitle: 'Cookie Info'
    },

    {
        path: '/team-subscription',
        component: TeamSubscriptionScreen,
        type: 'team-subscription',
        serverApi: false
    },

    {
        path: '/my-team',
        component: MyTeamScreen,
        serverApi: false,
        type: 'my-team'
    },

    {
        path: '/accept-team-invitation',
        component: VerifyMemberInviteScreen,
        serverApi: false,
        type: 'accept-team-invitation',
        pageTitle: 'Verify Member Invite'
    },

    {
        path: '/no-subscription-plan',
        component: NoSubscriptionPlanScreen,
        serverApi: false
    },

    {
        path: '/student-subscription',
        component: StudentSubscriptionScreen,
        type: 'student-subscription',
        serverApi: false
    },

    {
        path: '/student-documents/:document_slug',
        component: DocumentApprovalScreen,
        type: 'student-documents',
        serverApi: false
    },

    {
        path: '/view-user/:reference_id',
        component: AdminViewUserProfileScreen,
        type: 'admin-view-user',
    },

    {
        path: '/gifting',
        component: GiftingScreen,
        serverApi: false
    },

    {
        path: '/app-download',
        component: DownloadApp,
        serverApi: false
    },
    {
        path: '/claim-gift',
        component: ClaimGiftScreen,
        serverApi: false
    },
    {
        path: '/yesterday',
        component: Yesterdays,
        type: "yesterday",
        serverApi: true
    },
    {
        path: '/yesterday/:slug',
        component: YesterdayStoryPage,
        type: "yesterday-edition",
        serverApi: true
    },
    {
        path: '/claim-sub',
        component: ClaimSub,
        type: 'claim-subscription',
        serverApi: false
    },
    {
        path: '/claim-sub-error',
        component: ClaimSubError,
        type: 'claim-subscription-error',
        serverApi: false
    },
    {
        path: '/who-reads-us',
        component: WhoReadsUs,
        type: 'who-reads-us',
        serverApi: false
    },
    {
        path: '/grievance-redressal',
        component: GrievanceReportScreen,
        type: 'grievance-redressal',
        serverApi: false
    },

    //ALWAYS KEEP LAST
    {
        path: '/:page_slug',
        component: DynamicPageScreen,
        type: 'dynamic-page',
        serverApi: true
    },
    {
        path: '/:category_slug/:post_slug',
        component: PostSingleScreen,
        type: 'post-single',
        serverApi: true
    },
]