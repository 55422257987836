import React from "react";
import Loader from "../../native/components/loader/index.js";
import { View } from "react-native";
import Cookies from "universal-cookie";
import AuthenticationWrapper from "../../native/components/authentication-wrapper/index.js";


class LogoutUserSession extends React.Component {
    componentDidMount(){
      this.props.auth.logout({ returnTo: window.location.origin });
      const logoutURL = process.env.OATH2_LOGOUT_URL
      window.location.assign(`${logoutURL}?next=${window.location.origin}`)
    }

    render(){
      return <>
            <View style={{ flexDirection: 'row', height: '100%', alignItems: 'center', justifyContent: 'center', position: 'absolute', width: '100%' }}>
                <Loader title={"logging out ..."}/>
            </ View>
      </>
    }
  }

export default AuthenticationWrapper(LogoutUserSession)