import React, { Component } from 'react'
import { View, ActivityIndicator, StyleSheet, Text } from 'react-native';

export class Loader extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = async () => {

    }

    componentWillUnmount() {

    }

    render() {

        return (
            <View style={{ height: '100%', alignItems: 'center', justifyContent: 'center', position: 'absolute', width: '100%' }}>
                <ActivityIndicator color='#039b40' size={50} style={{ zIndex: 9 }} />
                <View style={[styles.darkBackground]}></View>
                {this.props.title?<Text style={{ padding: 10 ,fontSize: 14, fontFamily:'PlayfairDisplay-Bold',fontWeight:700}}>{this.props.title}</Text>:<></>}
            </View>
        )
    }
}


const styles = StyleSheet.create({
    lightBackground: {
        backgroundColor: 'white',
    },
    darkBackground: {
        backgroundColor: 'rgba(0,0, 0, 0.4)',
    },
});

export default Loader;