import React, { Component } from "react";
import { Mixpanel } from "../../utils/mixpanel";

export class TouchableOpacityLink extends Component {
  render() {
    let { to, ...restOfProps } = { ...this.props };
    let restOfObj = {};

    if (typeof to === "string") {
      restOfObj = { pathname: to };
    } else if (typeof to === "object") {
      restOfObj = { ...to };
    } else if (typeof to === "function") {
      restOfObj = to(window.location);
    }
    const path =
      typeof this.props.to === "string"
        ? this.props.to
        : this.props.to?.pathname;

    return (
      <a
        href={path}
        {...restOfProps}
      />
    );
  }

  shouldHardRefresh = (path) => {
    const refreshRegex =
      /^\/(chaos|business|internet|yesterday|state-of-the-unicorns|about-us)(\/.+)?$/;
    return refreshRegex.test(path);
  };

  handleClick = (location) => {
    try {
      Mixpanel.track("Page link clicked", {
        pagename: this.props.to,
        from_page: location,
      });
    } catch (error) {
      console.log("mix panel tracking error =>", error);
    }

    const path =
      typeof this.props.to === "string"
        ? this.props.to
        : this.props.to?.pathname;

    window.location.href = path;
  };
}

export default TouchableOpacityLink;
