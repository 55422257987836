import React, { Component } from "react";
import { Text, View, TouchableOpacity } from "react-native";
import ButtonGradient from "../../components/button-gradient";
import { button, Colors, Flex, FontFamily } from "../../styles/appStyles";
import apiHelpers from "../../utils/api-helpers";
import { helpers } from "../../utils/helpers";
import getImagePath from "../../utils/image-helper";
import AuthenticationWrapper from "../authentication-wrapper";
import CustomImage from "../custom-image";
import PostContent from "../post-content";
import { renderBorderColor } from "../../screens/yesterday/renderBorderColor";
import truncate from "truncate-html";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";

const YesterdayLogo1 = getImagePath("img/YesterdayLogo1.png");
const yesterdayBadge = getImagePath("img/yesterdayBadge.png");

const bg2 = getImagePath("img/bg2.png");
const yesterdayLogo = getImagePath("icons/yesterdayLogo.png");
const healthcare = getImagePath("icons/healthcare.png");
const rightArrow = getImagePath("icons/rightArrow.png");
export class HomeYesterdayStory extends Component {
  constructor(props) {
    super(props);
    let webView = helpers.isWeb();
    // const {isAuthenticated, fetchToken} = this.props.auth
    // let token = webView ? (process.browser ? (isAuthenticated?fetchToken:false) : this.props.token) : this.props.user?.userData?.data?.token;
    this.state = {
      isUserLoggedIn: this.props.token,
      storyData: [],
    };
  }

  componentDidMount = () => {
    this.getEditions();
  };

  pushInformationToDataLayer = () => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: "more_info_interaction",
      custom_user_id: this.props.custom_user_id || "NA",
      user_status: this.props.custom_user_id ? "logged in" : "guest",
      article_section: "yesterday",
      newsletter_section: "NA",
      CTA_text: helpers.isDesktop()
      ? "read more"
      : "read more",
      CTA_position: "top",
      category_type: "NA",
      scroll_depth: helpers.getScrollDepth(),
    });
  }


  getEditions = () => {
    const { token, isUserLoggedIn } = this.state;
    let endpoint = `/yesterday/editions`;
    let body = {};
    body = {
      sort_on: "published_date",
      sort_by: "DESC",
      // published_date: moment(new Date()).format("DD-MM-YYYY"),
      start_date: moment(new Date()).subtract(300, "days").format("DD-MM-YYYY"),
      end_date: moment(new Date()).subtract(2, "days").format("DD-MM-YYYY"),
      limit: 4,
      truncate: 1,
      count: 120,
    };
    apiHelpers
      .apiRequest(endpoint, body, "POST", true, false)
      .then((res) => {
        this.setState({
          storyData: res.data.editions[0],
          yesterdayStories: res.data.editions
        });
        console.log("Debug edition:>>>>", res.data)
      })
      .catch((error) => {
        console.log("Error=>> down", JSON.stringify(error));
      });
  };

  render() {
    let { darkMode, token } = this.props;
    let { storyData, yesterdayStories } = this.state;

    return (
      <View
        style={{
          marginTop: helpers.isDesktop() ? "0" : 0,
          width: "100%",
          backgroundColor: darkMode ? "#121212" : "transparent",
        }}
      >
        <View style={[darkMode && { backgroundColor: "transparent" }]}>
          <View
            style={[
              helpers.isDesktop() && {
                maxWidth: "100%",
                width: "100%",
                margin: "auto",
              },
            ]}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  width: 151.23,
                  position: "relative",
                }}
              >
                {/* <CustomImage
                  resizeMode={"contain"}
                  source={YesterdayLogo1}
                  require={true}
                  style={[
                    helpers.isDesktop()
                      ? { width: "100%", height: "auto" }
                      : { width: "100%", height: 20 },
                  ]}
                  webStyle={{ backgroundColor: darkMode ? "white" : "" }}
                  altText={"Yesterday Logo"}
                /> */}

                <View
                  style={{
                    clipPath:
                      "polygon(0 0, 100% 0, 100% 100%, 50% 83%, 0 100%)",
                    background:
                      "linear-gradient(17.24deg, #55C79A 9.08%, #907CFF 58.44%, #FC876D 103.84%)",
                    width: 6.05,
                    height: 12.73,
                    position: "absolute",
                    left: 0,
                    top: "13%",
                  }}
                ></View>

                <Text
                  style={{
                    fontSize: 15,
                    fontWeight: "700",
                    color: "#0D256C",
                    paddingLeft: 12.29,
                    lineHeight: 19,
                    fontFamily: "Matteo-bold",
                  }}
                >
                  NEWS EXPLAINER
                </Text>
              </View>
              <Text
                style={[
                  {
                    fontSize: helpers.isDesktop() ? 16 : 16,
                    lineHeight: helpers.isDesktop() ? 19 : 19,
                    fontFamily: FontFamily.regular,
                    color: darkMode
                      ? "white"
                      : helpers.isDesktop()
                      ? "rgba(41, 41, 41, 0.5)"
                      : "rgba(41, 41, 41, 0.5)",
                    fontWeight: "400",
                    letterSpacing: "1px",
                  },
                  { marginBottom: 12, textAlign: "left" },
                  darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                ]}
              >
                {moment(new Date()).add(5, 'hours').add(30, 'minutes').format("DD MMMM, YYYY")}
              </Text>
            </View>
            {
              yesterdayStories && yesterdayStories.map((storyData)=>{
                return <View>{storyData &&
                  storyData.posts &&
                  storyData.posts.map((item, index) => {
                    if(index !== 0) return
                    return (
                      <View
                        style={[
                          { backgroundColor: "#F3F5FF" },
                          { marginBottom: helpers.isDesktop() ? 32 : 24 },
                          darkMode && { backgroundColor: Colors.darkPurple },
                        ]}
                      >
                        <TouchableOpacity
                          onPress={(event) => {
                            const url = `/yesterday/${storyData?.slug}?post_id=${index}`;
                            if (event.metaKey || event.ctrlKey) {
                              window.open(url, '_blank');
                            }else{
                              this.props.navigateWeb(
                                `/yesterday/${storyData?.slug}?post_id=${index}`
                              );
                            }
                            
                            try {
                              console.log("Yesterday - Click Story");
                              window.webengage.track("Yesterday - Click Story", {
                                "No of clicks": 1,
                                "Story slug": storyData?.slug,
                              });
                              console.log({
                                "No of clicks": 1,
                                "Story slug": storyData?.slug,
                              });
                            } catch (err) {}
                          }}
                        >
                          <View
                            style={[
                              {
                                borderLeftWidth: 4,
                                borderColor: renderBorderColor(item?.category[0]),
                                paddingTop: 16,
                                paddingLeft: 20,
                                paddingRight: 16,
                                paddingBottom: 16,
                              },
                            ]}
                          >
                            <Text
                              style={[
                                {
                                  fontSize: helpers.isDesktop() ? 18 : 18,
                                  lineHeight: helpers.isDesktop() ? 19.8 : 19.8,
                                  fontFamily: FontFamily.bold,
                                  fontWeight: 600,
                                  color: "#051462",
                                },
                                {
                                  textAlign: "left",
                                  marginBottom: 10,
                                },
                                darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                              ]}
                            >
                              {item.title}
                            </Text>
                            <Text
                              style={[
                                {
                                  fontSize: 16,
                                  lineHeight: 23.48,
                                  fontFamily: FontFamily.regular,
                                  color: "#051462",
                                  fontWeight: 400,
                                },
                                { textAlign: "left" },
                                darkMode && { color: "rgba(255, 255, 255, 0.89)" },
                              ]}
                              numberOfLines={3}
                            >
                              {ReactHtmlParser(item.content)}
                            </Text>
                            {/* <PostContent
                                data={truncate(item.content, 10, { byWords: true })}
                                darkMode={darkMode}
                                openLink={(link) => this.openSocialMedia(link)}
                              /> */}
                          </View>
                        </TouchableOpacity>
                      </View>
                    );
                  })} </View>
              })
            }

            <View
              style={[
                Flex.row,
                helpers.isDesktop() ? Flex.justifyStart : Flex.justfiyCenter,
                {
                  marginTop: helpers.isDesktop() ? 0 : 8,
                },
              ]}
            >
              <View style={{ width: helpers.isDesktop() ? 154 : "100%" }}>
                <ButtonGradient
                  rounded={true}
                  title={
                    helpers.isDesktop()
                      ? "READ MORE"
                      : "READ MORE"
                  }
                  rootStyle={{
                    btnWrapper: [
                      button.primaryGradient,
                      { width: "100%", borderRadius: "0" },
                    ],
                    btnText: [
                      button.primaryGradientText,
                      {
                        fontSize: helpers.isDesktop() ? 17 : 16,
                        lineHeight: 20,
                        letterSpacing: "0.05em",
                        textTransform: "uppercase",
                        fontWeight: "600",
                        fontFamily: FontFamily.regular,
                      },
                    ],
                  }}
                  onClick={() => {
                    if(false){
                      this.props.navigateWeb(`/yesterday/${storyData?.slug}`);
                    }else{
                      this.props.navigateWeb(`/yesterday`);
                    }
                    this.pushInformationToDataLayer()
                    try {
                      console.log("Clicks Yesterday_Menu");
                      window.webengage.track("Clicks Yesterday_Menu", {
                        "No of clicks": 1,
                      });
                      console.log({
                        "No of clicks": 1,
                      });
                    } catch (err) {
                      console.log("err", err);
                    }
                  }}
                  darkMode={darkMode}
                />
              </View>
            </View>
            {!helpers.isDesktop() ? (
              <View
                style={{
                  display: "flex",
                  marginTop: helpers.isDesktop() ? 0 : 20,
                  // marginBottom: helpers.isDesktop() ? 0 : 59,
                  justifyContent: "flex-start",
                  width: helpers.isDesktop() ? 240 : "100%",
                }}
              >
                <ButtonGradient
                  rounded={true}
                  title={"Last Week's Stories"}
                  rootStyle={{
                    btnWrapper: [
                      button.primaryGradient,
                      { width: "100%", borderRadius: 0 },
                    ],
                    btnText: [
                      button.primaryGradientText,
                      {
                        fontSize: helpers.isDesktop() ? 17 : 16,
                        lineHeight: 20,
                        letterSpacing: "0.05em",
                        textTransform: "uppercase",
                        fontWeight: "600",
                        fontFamily: FontFamily.regular,
                      },
                    ],
                  }}
                  onClick={() => this.props.navigateWeb(`/last-week`)}
                  darkMode={darkMode}
                  isNewGradiant={true}
                />
              </View>
            ) : null}
          </View>
        </View>
      </View>
    );
  }
}

export default AuthenticationWrapper(HomeYesterdayStory);
