import React, { Component } from "react";
import { withRouter, Redirect, Link } from "react-router-dom";
import { Text, View, Image, ScrollView, Platform } from "react-native";
import SignIn from "../../native/screens/sign-in";
import ReactGA from "react-ga";
import { helpers } from "../../native/utils/helpers";
import AuthenticationWrapper from "../../native/components/authentication-wrapper";
import Auth0 from "react-native-auth0";
import { bindActionCreators } from "redux";
import { setUserToken } from "../../redux/User/user.actions";
import { connect } from "react-redux";
import apiHelpers from "../../native/utils/api-helpers";
import Loader from "../../native/components/loader/index.js";

export class SignInScreen extends Component {
  constructor(props) {
    super(props);

        let redirectUrl = null;
        let verifyRedirectUrl = null;
        let allParams = null;
        let webView = helpers.isWeb();

        if (webView && process.browser && window.location.search.includes("redirect=")) {
            redirectUrl = this.getRedirectURL(window.location.href, window.location.search)
            verifyRedirectUrl = redirectUrl
        } else if (!webView && this.props.route.params) {
            redirectUrl = (this.props.route.params.redirect) ? this.props.route.params.redirect : null;
            verifyRedirectUrl = (this.props.route.params.verifyRedirect) ? this.props.route.params.verifyRedirect : null;
            allParams = this.props.route.params;
        }

        this.state = {
            searchQuery: "",
            locationHash: "",
            userIpAddress: "",
            enableBtnLoader: false,
            redirectUrl: redirectUrl,
            verifyRedirectUrl: verifyRedirectUrl,
            allParams: allParams
        }
        if(process.browser) {
            try {
                ReactGA.pageview(this.props.history.location.pathname);
            }
            catch(error) {
                console.log("GA tracking error =>", error);
            }
        }
    }

    getLoginHint = (urlString) => {
        let urlParamDict = helpers.getURLParamDict(urlString)
        const providerHint = urlParamDict['providerHint']
        const emailHint = urlParamDict['emailHint']

        let loginHint = {}

        if (providerHint){
            loginHint['provider_hint'] = providerHint
        }

        if (emailHint){
            loginHint['email_hint'] = emailHint
        }

        return loginHint
    }

    // 1. check encode param
    // 2. decode all params except encode param
    // 3. if there is no encode then follow earlier flow
    getRedirectURL = (urlString, urlParamString, encoded=true) => {
        let urlParamDict = helpers.getURLParamDict(urlString)
        let encodedParams = urlParamDict['encoded']
        let redirectURL = ''

        if(encodedParams == '1'){
            redirectURL = this.getEncodedRedirectURL(urlParamDict)
        }
        else{
            redirectURL = this.getUnEncodedRedirectURL(urlParamDict, urlParamString)
        }
        return redirectURL
    }

    /*
        Redirect URL encoded as a URI component
    */

    getEncodedRedirectURL = (urlParamDict, redirectParamKey='redirect') => {
        let decodedParamDict = helpers.getDecodedParamDict(urlParamDict)

        let redirectURL = decodedParamDict[redirectParamKey]
        if(!redirectURL){
            return ''
        }
        return redirectURL;
    }

    /*
        Depreciated redirect URL format which is used for outdated redirections
    */

    getUnEncodedRedirectURL = (urlParamDict, URLParamString) => {
        let searchArr = URLParamString.replace("?", "").split("&");
        console.log('user-subs:Login:searchArr: ', searchArr)
        let foundSearch = searchArr.find((searchParam) => {
            return searchParam.includes("redirect=");
        });
        let redirectParams = searchArr.find((searchParam) => {
            return searchParam.includes("redirect-params=");
        });
        let redirectUrl = ''
        if (foundSearch) {
            let search = foundSearch.replace("redirect=", "");
            redirectUrl = (search != '') ? search : '';
        }
        if (redirectParams) {
            console.log('user-subs:login-params-1: ', redirectParams)
            redirectParams = redirectParams.replace("redirect-params=", "")
            console.log('user-subs:login-params-2: ', redirectParams)
            redirectUrl = redirectUrl + '?' + redirectParams
        }
        return redirectUrl
    }

    getIpAccessInfo = async () => {
        const endpoint = `/verify-ip-access`
        let body = {}
        try{
            const ipInfo = await apiHelpers.apiRequest(endpoint, body, "POST", true, false)
            if(ipInfo.instituteAccess === true){
                return {
                    institute_access: true,
                }
            }
        }
        catch(err){
            console.log('IP error: ', err)
        }
        return false
    }


    componentDidMount = () => {
        const { login } = this.props.auth;
        const redirectTo = decodeURIComponent(this.state.redirectUrl);
        let defaultParams = {appState: { returnTo: redirectTo}}
        login({
            ...defaultParams,
            ...this.getLoginHint(window.location.href)
        });

    }


    componentDidUpdate = () => {

    }

    render(){
        return (
            <View style={{ flexDirection: 'row', height: '100%', alignItems: 'center', justifyContent: 'center', position: 'absolute', width: '100%' }}>
                <Loader title={"Please wait while we sign you in ..."}/>
            </ View>
        )

    }
}

const mapStateToProps = (state) => {
  const { app, user } = state;
  return { app, user };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setUserToken }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationWrapper(SignInScreen));
