import React, { Component } from 'react';
import { Text, View, Image, ScrollView, Platform, TouchableOpacity, ActivityIndicator, FlatList, Dimensions, Linking } from 'react-native';
import { PostList, Flex, Padding, Margin, Helpers, Typo, appStyles, postSingle, writerSingle, article, articleLists, articleItem, FontFamily, tweetsContainer, Colors } from '../../styles/appStyles';
import { helpers } from '../../utils/helpers';
import apiHelpers from "../../utils/api-helpers";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ErrorModal from '../../components/error-modal';
import { removeUserToken } from '../../../redux/User/user.actions';
import AppHeader from '../../components/app-header';
import AppFooter from '../../components/app-footer';
import CustomImage from '../../components/custom-image';
import getImagePath from '../../utils/image-helper';
import TouchableOpacityLink from '../../components/touchable-opacity-link';
import TouchableOpacityLinkExternal from '../../components/touchable-opacity-link-external';
import ArticleItemVertical from '../../components/article-item-vertical';
import InfiniteScrollComponent from '../../components/infinite-scroll';
import ContentLoader from "react-native-easy-content-loader";
import FeaturedArticle from '../../components/featured-article';
import PageBreak from '../../components/page-break';
import { Mixpanel } from '../../utils/mixpanel';
import Seo from "../../components/seo-meta";

// import { TwitterTimelineEmbed } from "react-twitter-embed";
import TwitterFeeds from '../../components/twitter-feeds';
import seoObj from '../../utils/seo-helper';

const twitterIcon = getImagePath('icons/twitterIcon.png');
const linkedinIcon = getImagePath('icons/linkedin_icon.png');
const emailIcon = getImagePath('icons/email.png');

const priyaPhoto = getImagePath('img/priya-profile.jpg');

const homeSeoImage = getImagePath('img/extras/app-seo.png');

const priyaData = {
    "id": "0",
    "name": "Priya Bubna",
    "email": "priya@mailtmc.com",
    "slug": "priya",
    "biography": "Priya is the promoter of Slowform Holdings Ltd, the publisher of The Morning Context. She is a chartered accountant and a graduate of the Shri Ram College of Commerce. Priya specializes in international taxation and transfer pricing, where she has assisted clients on transfer pricing planning, complex advisories, litigation and representation across a broad range of industries. She has previously worked at EY and BMR Advisors.",
    "profile_image": priyaPhoto,
    "location": "",
    "designation": "",
    "social_links": {
        "wikipedia": "",
        "youtube": "",
        "twitter": "https://twitter.com/Meanderingways",
        "tumblr": "",
        "soundcloud": "",
        "pinterest": "",
        "myspace": "",
        "linkedin": "https://www.linkedin.com/in/priya-bubna-b6601bb",
        "instagram": "",
        "facebook": ""
    },
    "story_count": 0
}


export class WriterSingle extends Component {
    constructor(props) {
        super(props);
        let webView = helpers.isWeb();
        let writerSlug = '';
        if (webView && this.props.writerSlug) {
            writerSlug = this.props.writerSlug;
        } else if (!webView) {
            writerSlug = this.props.route.params.writerSlug;
        }
        this.state = {
            writerData: this.props.data ? (this.props.writerData ? this.props.writerData.author : '') : '',
            writerDataSSR: this.props.data ? true : false,
            writerSlug: writerSlug,
            pageLoader: this.props.data ? false : true,
            showErrorModal: false,
            authorArticleData: this.props.writerData ? this.props.writerData.authorArticleData ? this.props.writerData.authorArticleData : '' : '',
            authorFeaturedStory: this.props.writerData ? (this.props.writerData.authorArticleList ? (this.props.writerData.authorArticleList.length > 0 ? this.props.writerData.authorArticleList[0] : false) : false) : false,
            authorArticleList: this.props.writerData ? (this.props.writerData.authorArticleList ? (this.props.writerData.authorArticleList.length > 1 ? this.props.writerData.authorArticleList.splice(1) : false) : false) : false,
            footerData: this.props.footerData ? this.props.footerData : false,
            fetchYesterdayArticles: false,
            yesterdayPage: 0
        }
    }

    componentDidMount = () => {

        if (!this.state.writerDataSSR) {
            if (this.state.writerSlug == 'priya') {
                this.setState({
                    writerData: priyaData,
                    authorArticleList: false,
                    pageLoader: false
                });
            }
            else {
                this.getPost();
            }
        }
        else if (this.state.authorArticleData) {
            this.getArticles(1);
            // this.getYesterdayArticles(1);
        }

        if (this.state.writerDataSSR) {
            this.setBreadcrumbs(this.state.writerData)
        }
    }

    componentDidUpdate = () => {
        let webView = helpers.isWeb();
        let writerSlug = webView ? this.props.writerSlug : this.props.route.params.writerSlug;
        if (writerSlug != this.state.writerSlug) {
            this.setState({
                writerSlug: writerSlug
            }, () => this.getPost());
        }
        // if(this.state.fetchYesterdayArticles && !this.state.authorArticleList.length){
        //     this.getYesterdayArticles(1)
        // }
    }

    setBreadcrumbs = (data) => {
        Breadcrumbs = [
            {
                name: 'Home',
                link: '/'
            },
            {
                name: 'Writers',
                link: `/writers`
            },
            {
                name: data.name
            }
        ]
    }

    getSeoObject = () => {
        let { writerData } = this.state;
        if (writerData) {
            return seoObj.getWriterSingleSeo(writerData);
        }
        return {};
    }

    getPost = () => {
        this.setState({
            showErrorModal: false,
            pageLoader: true,
        });
        const { token } = this.state;
        let endpoint = `/author/${this.state.writerSlug}`;
        apiHelpers
            .apiRequest(endpoint, {}, "GET", true, token)
            .then((res) => {
                if (res.success && res.code == 'author_found') {
                    this.setState({
                        writerData: res.data.author,
                        authorArticleList: false,
                        pageLoader: false
                    });
                    if (res.data.author.story_count > 0) {
                        // if(this.state.fetchYesterdayArticles){
                        //     this.getYesterdayArticles(1);
                        // }else{
                            this.getArticles(1);
                        // }
                    }
                    this.setBreadcrumbs(res.data.author);
                } else {
                    this.setState({
                        showErrorModal: true,
                        pageLoader: false
                    });
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    showErrorModal: true,
                    pageLoader: false
                });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    getArticles = (page, append) => {
        let endpoint = `/stories`;
        let body = {
            limit: 6,
            page: page,
            sort_on: 'date',
            sort_by: 'desc',
            filters: [
                {
                    key: 'author',
                    value: [this.state.writerSlug]
                },
                {
                    key: 'type',
                    value: ["regular", "yesterday", "newsletter"]
                  }
            ]
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true)
            .then((res) => {
                if (res.success && res.code == 'story_found') {
                    this.setState({
                        authorArticleData: res.data,
                        authorFeaturedStory: !append ? res.data.story[0] : this.state.authorFeaturedStory,
                        authorArticleList: append ? this.state.authorArticleList.concat(res.data.story) : (res.data.story.length > 1 ? res.data.story.splice(1) : false),
                        pageLoader: false
                    });
                    if(!res.data.page.has_next){
                        this.setState({
                            fetchYesterdayArticles: true
                        })
                    }
                } else {
                    this.setState({
                        showErrorModal: false,
                        pageLoader: false,
                        fetchYesterdayArticles: true
                    });
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    showErrorModal: true,
                    pageLoader: false
                });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }

    getYesterdayArticles = (page, append) => {
        let endpoint = `/yesterday/editions`;
        let body = {
            limit: 6,
            page: page,
            sort_on: 'date',
            sort_by: 'desc',
            filters: [
                {
                    key: 'author',
                    value: [this.state.writerSlug]
                },
            ]
        }
        apiHelpers
            .apiRequest(endpoint, body, "POST", true)
            .then((res) => {
                if (res.data?.editions?.length) {
                    this.setState({
                        authorArticleData: res.data,
                        authorFeaturedStory: !append && !this.state.authorFeaturedStory ? res.data.editions[0] : this.state.authorFeaturedStory,
                        authorArticleList: (append || this.state.authorArticleList.length) ? this.state.authorArticleList.concat(res.data.editions) : (res.data.editions.length > 1 ? res.data.editions.splice(1) : false),
                        pageLoader: false,
                        yesterdayPage: this.state.yesterdayPage + 1
                    });
                } else {
                    this.setState({
                        showErrorModal: false,
                        pageLoader: false
                    });
                    console.log('Error=>>', JSON.stringify(res));
                }
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    showErrorModal: true,
                    pageLoader: false
                });
                console.log('Error=>> down', JSON.stringify(error));
            });
    }


    getMoreArticles = () => {
        // if(this.state.fetchYesterdayArticles){
        //     this.getYesterdayArticles(this.state.yesterdayPage + 1, true);
        // }else{
            this.getArticles(this.state.authorArticleData.page.current + 1, true)
        // }
    }

    getModalClickEvent = () => {

    }

    viewArticle = (catSlug, slug, postData) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/' + catSlug + '/' + slug);
        } else {
            this.props.navigation.navigate('post-single', { categorySlug: catSlug, postSlug: slug, postData: postData });
        }
    }

    viewAuthor = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/writers/' + slug);
        } else {
            this.props.navigation.navigate('writer-single', { writerSlug: slug });
        }
    }

    viewCategory = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/category/' + slug);
        } else {
            this.props.navigation.navigate('category', { categorySlug: slug });
        }
    }

    navigateToCategory = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/category/' + slug);
        } else {
            this.props.navigation.navigate('category', { categorySlug: slug });
        }
    }

    navigateToAuthor = (slug) => {
        if (helpers.isWeb()) {
            this.props.navigateToScreen('/writers/' + slug);
        } else {
            this.props.navigation.navigate('writer-single', { writerSlug: slug });
        }
    }

    openSocialMedia = (link) => {
        // Linking.openURL(link);
        this.refs.header.openLink(link);
    }

    trackStoryClick = (name, position, title) => {
        // try {
        //     Mixpanel.track('author', { 'name': name, 'title': title, 'position': position, });
        // }
        // catch (error) {
        //     console.log("mix panel error =>", error)
        // }
    }

    renderPageContent = () => {
        const { writerData, authorArticleList, authorFeaturedStory } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);

        let btnClickTwitter, btnClickLinkdin, btnClickEmail;
        if (helpers.isWeb()) {
            if (writerData.social_links.twitter) {
                btnClickTwitter = {
                    href: writerData.social_links.twitter
                }
            }
            if (writerData.social_links.linkedin) {
                btnClickLinkdin = {
                    href: writerData.social_links.linkedin
                }
            }
            if (writerData.email) {
                btnClickEmail = {
                    href: `mailto:${writerData.email}`
                }
            }
        }
        else {
            if (writerData.social_links.twitter) {
                btnClickTwitter = {
                    onPress: () => this.openSocialMedia(writerData.social_links.twitter)
                }
            }
            if (writerData.social_links.linkedin) {
                btnClickLinkdin = {
                    onPress: () => this.openSocialMedia(writerData.social_links.linkedin)
                }
            }
            if (writerData.email) {
                btnClickEmail = {
                    onPress: () => Linking.openURL(`mailto:${writerData.email}`)
                }
            }
        }
        return (
            <View style={[Margin.mt_3]}>
                <View style={[Padding.ph_2, Margin.mb_1]}>
                    <View style={[Flex.alignCenter, Margin.mt_3, helpers.isDesktop() && Margin.mb_4, !helpers.isDesktop() && Margin.mb_2]}>
                        <CustomImage
                            source={writerData.profile_image}
                            style={{ width: helpers.isDesktop() ? 200 : 150, height: helpers.isDesktop() ? 200 : 150 }}
                            webStyle={{ width: helpers.isDesktop() ? 200 : 150, height: helpers.isDesktop() ? 200 : 150 }}
                            require={this.state.writerSlug == 'priya' ? true : false}
                            altText={writerData.name}
                        />
                    </View>
                    <View>
                        <Text accessibilityRole="header" aria-level="1" style={[writerSingle.authorName, helpers.isDesktop() && writerSingle.authorNameDesktop, { fontSize: helpers.isDesktop() ? 34 : 24, lineHeight: helpers.isDesktop() ? 42 : 29, fontFamily: helpers.isDesktop() ? FontFamily.abrilFatface : FontFamily.regular, }, darkMode && { color: Colors.lightWhite }]}>{writerData.name}</Text>
                        <View style={[Flex.row, Flex.justfiyCenter, Flex.alignCenter, Margin.mt_2]}>
                            <>
                                {(writerData.designation != '') && <Text style={[writerSingle.position, { whiteSpace: 'nowrap', fontSize: helpers.isDesktop() ? 20 : 16 }, darkMode && { color: Colors.lightWhite }]}>{writerData.designation}</Text>}
                                {(writerData.designation != '') && <View style={[writerSingle.dot]} />}
                                {(writerData.location != '') && <Text style={[writerSingle.location, { color: '#6A6A6A', fontSize: helpers.isDesktop() ? 20 : 16 }, darkMode && { color: Colors.lightWhite }]}>{writerData.location}</Text>}
                                {(writerData.location != '') && <View style={[writerSingle.dot]} />}
                                {(writerData.story_count > 0) && <Text style={[writerSingle.articleCount, { fontSize: helpers.isDesktop() ? 20 : 16 }, darkMode && { color: Colors.lightWhite }]}>{writerData.story_count}<Text style={[writerSingle.articleCountLabel, darkMode && { color: Colors.lightWhite }]}> {writerData && writerData.story_count == 1 ? 'Article' : 'Articles'}</Text></Text>}
                            </>

                        </View>
                    </View>
                </View>
                <View>
                    <View style={[Helpers.conatinerWeb, Padding.ph_2, Padding.pv_2, helpers.isDesktop() && Helpers.conatinerWeb1200]}>
                        <Text style={[writerSingle.bioText, helpers.isDesktop() && writerSingle.bioTextDesktop, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb800, { fontSize: helpers.isDesktop() ? 18 : 16, lineHeight: 26 }, darkMode && { color: Colors.lightWhite }]}>{writerData.biography}</Text>
                    </View>
                    {(!!writerData.social_links.twitter || !!writerData.social_links.linkedin || !!writerData.email) && <View style={[Padding.ph_2, Margin.mt_2, Margin.mb_2]}>
                        <View style={[Flex.row, Flex.alignCenter, Flex.justfiyCenter, Margin.mb_5, {}]}>
                            {!!writerData.social_links.twitter &&
                                <TouchableOpacityLinkExternal {...btnClickTwitter}>
                                    <View style={(!!writerData.social_links.twitter) && [Margin.mr_5]}>
                                        <CustomImage
                                            source={twitterIcon}
                                            style={{ width: 27, height: 22 }}
                                            webStyle={{ width: 27, height: 22 }}
                                            require={true}
                                            altText={'Twitter'}
                                        />
                                    </View>
                                </TouchableOpacityLinkExternal>
                            }
                            {!!writerData.social_links.linkedin &&
                                <TouchableOpacityLinkExternal {...btnClickLinkdin}>
                                    <View style={(!!writerData.social_links.linkedin) && [Margin.mr_5]}>
                                        <CustomImage
                                            source={linkedinIcon}
                                            style={{ width: 22, height: 22 }}
                                            webStyle={{ width: 22, height: 22 }}
                                            require={true}
                                            altText={'Linkedin'}
                                        />
                                    </View>
                                </TouchableOpacityLinkExternal>
                            }
                            {!!writerData.email &&
                                <TouchableOpacityLinkExternal {...btnClickEmail}
                                    activeOpacity={0.8}>
                                    <CustomImage
                                        source={emailIcon}
                                        style={{ width: 35, height: 24 }}
                                        webStyle={{ width: 35, height: 24 }}
                                        require={true}
                                        altText={'Email'}
                                    />
                                </TouchableOpacityLinkExternal>
                            }
                        </View>
                    </View>
                    }
                </View>
                <View style={[helpers.isDesktop() && Margin.mt_40]}>
                    {helpers.isWeb() ? <View style={[Flex.alignStart, Margin.mb_2]}>
                        {authorFeaturedStory && <><View style={[Helpers.titleBgWrap, Helpers.conatinerWeb, Helpers.conatinerWeb1200, darkMode && { backgroundColor: Colors.darkBlackColorBg }]}>
                            <View style={[helpers.isDesktop() && Flex.alignStart, !helpers.isDesktop() && Flex.alignCenter, Margin.mb_2]}>
                                <View style={[Padding.ph_2, Helpers.titleBgWrap, darkMode && { backgroundColor: Colors.darkBlackColorBg }]}>
                                    <View style={[Helpers.borderBottom, darkMode && { borderBottomColor: Colors.lineColor }]}>
                                        <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: helpers.isDesktop() ? 30 : 20, fontFamily: FontFamily.abrilFatface, color: '#000' }, helpers.isDesktop() && { width: helpers.isWeb() ? 'fit-content' : '100%' }, darkMode && { color: Colors.lightWhite }]}>Featured Story</Text>
                                    </View>
                                </View>
                                {!helpers.isDesktop() && <View style={[Helpers.titleLine, { top: 15 }, darkMode && { backgroundColor: Colors.lineColor }]}></View>}
                            </View>
                        </View>
                            <FeaturedArticle data={authorFeaturedStory} category={authorFeaturedStory.category} viewArticle={(catSlug, slug, postData) => this.viewArticle(catSlug, slug, postData)} viewAuthor={(slug) => this.viewAuthor(slug)} writers={true} trackStoryClick={(title) => this.trackStoryClick(writerData.name, 'hero story', title)} darkMode={darkMode} /></>}
                    </View>
                        :
                        <View style={[Margin.mb_2]}>
                            {authorFeaturedStory && <><View style={[Helpers.titleBgWrap, darkMode && { backgroundColor: Colors.darkBlackColorBg }]}>
                                <View style={[helpers.isDesktop() && Flex.alignStart, !helpers.isDesktop() && Flex.alignCenter, Margin.mb_2]}>
                                    <View style={[Padding.ph_2, Helpers.titleBgWrap, darkMode && { backgroundColor: Colors.darkBlackColorBg }]}>
                                        <View style={[Helpers.borderBottom, darkMode && { borderBottomColor: Colors.lineColor }]}>
                                            <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: helpers.isDesktop() ? 30 : 20, fontFamily: FontFamily.abrilFatface, color: '#000' }, helpers.isDesktop() && { width: helpers.isWeb() ? 'fit-content' : '100%' }, darkMode && { color: Colors.lightWhite }]}>Featured Story</Text>
                                        </View>
                                    </View>
                                    {!helpers.isDesktop() && <View style={[Helpers.titleLine, { top: 15 }, darkMode && { backgroundColor: Colors.lineColor }]}></View>}
                                </View>
                            </View>
                                <FeaturedArticle data={authorFeaturedStory} category={authorFeaturedStory.category} viewArticle={(catSlug, slug, postData) => this.viewArticle(catSlug, slug, postData)} viewAuthor={(slug) => this.viewAuthor(slug)} writers={true} trackStoryClick={(title) => this.trackStoryClick(writerData.name, 'hero story', title)} darkMode={darkMode} /></>}
                        </View>
                    }
                </View>
                {helpers.isDesktop() && authorArticleList && <View>
                    <View style={[Helpers.titleLine, darkMode && { backgroundColor: Colors.lineColor }]}></View>
                </View>}
                <View style={[Padding.ph_0, helpers.isDesktop() && Flex.row, !helpers.isDesktop() && { flexDirection: 'column-reverse' }, helpers.isDesktop() && Margin.mt_7, Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWeb1200]}>
                    <View style={[{ width: helpers.isDesktop() ? '95%' : '100%' }, !helpers.isDesktop() && Margin.mt_6, helpers.isDesktop() && Margin.mb_6]}>
                        {authorArticleList && this.renderAuthorPost()}
                    </View>
                    {/* <View style={{ width: helpers.isDesktop() ? '28%' : 'auto', marginHorizontal: helpers.isDesktop() ? 30 : 0 }}>
                        {authorArticleList && <View style={[Flex.row, { width: '100%' }, { flexWrap: 'wrap', height: helpers.isWeb() ? 'fit-content' : 'auto' }]}>
                            {this.renderTweets()}
                        </View>}
                    </View> */}
                </View>
            </View>
        )
    }

    renderTweets = () => {
        return null
        let { writerData } = this.state;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let twitter_link = writerData.social_links.twitter;
        let user_name = twitter_link ? twitter_link.split('https://twitter.com/')[1] : 'MorningContext';
        return (
            <View style={[helpers.isDesktop() && article.headerWrapper, helpers.isDesktop() && tweetsContainer.topBorder, { width: '100%' }]}>
                {helpers.isDesktop() ?
                    <View style={[Flex.alignCenter, Margin.mb_5, Margin.mt_2, !helpers.isDesktop() && Margin.mb_4]}>
                        <View style={[Padding.ph_2, Helpers.titleBgWrap, darkMode && { backgroundColor: Colors.darkBlackColorBg }]}>
                            <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: 18, fontFamily: FontFamily.abrilFatface, color: '#000' }, { fontSize: helpers.isDesktop() ? 30 : 20 }, darkMode && { color: Colors.lightWhite }]}>Recent Tweets</Text>
                        </View>
                    </View>
                    :
                    <View style={[Flex.alignCenter, Margin.mb_7, !helpers.isDesktop() && Margin.mb_4]}>
                        <View style={[Padding.ph_2, Helpers.titleBgWrap, darkMode && { backgroundColor: Colors.darkBlackColorBg }]}>
                            <View style={[Helpers.borderBottom, darkMode && { borderBottomColor: Colors.lineColor }]}>
                                <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: 18, fontFamily: FontFamily.abrilFatface, color: '#000' }, { fontSize: helpers.isDesktop() ? 30 : 20 }, darkMode && { color: Colors.lightWhite }]}>Recent Tweets</Text>
                            </View>
                        </View>
                        <View style={[Helpers.titleLine, darkMode && { backgroundColor: Colors.lineColor }]}></View>
                    </View>
                }
                <View style={[helpers.isDesktop() && Padding.ph_2, Margin.mb_4, { marginHorizontal: helpers.isDesktop() ? 0 : 20 }]}>
                    <TwitterFeeds UserName={user_name} darkMode={darkMode} />
                </View>
            </View>
        )
    }

    trackCateoryClick = (item) => {
        // try {
        //     Mixpanel.track('category', { category_slug: item.category.slug, source_section_type: 'writer_latest_stories', section_value: { title: item.name, slug: item.slug } });
        // }
        // catch (error) {
        //     console.log("mix panel error =>", error);
        // }
    }

    renderAuthorPost = () => {
        let { authorArticleList, authorArticleData, writerData } = this.state;
        let btnClickCategory;
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        const hasNextPage = authorArticleData?.page?.has_next;

        let items = authorArticleList.map((item, index) => {
            if (helpers.isWeb()) {
                btnClickCategory = {
                    to: item?.category?.slug ? "/category/" + item.category.slug : 'yesterday/' + item.slug
                }
            } else {
                btnClickCategory = {
                    onPress: () => this.viewCategory(item.category.slug)
                }
            }
            return (
                <View style={[articleItem.col6]}>
                    <TouchableOpacityLink {...btnClickCategory} handleClick={() => this.trackCateoryClick(item)}>
                        <View>
                            <Text style={[articleLists.badge, !helpers.isDesktop() && {paddingLeft: 10}, { width: !helpers.isDesktop() ? 200 : 150, marginBottom: 8, textAlign: 'left', fontFamily: FontFamily.regular, color: darkMode ? Colors.darkBlue : '#907cff' }, !helpers.isWeb() && { fontFamily: FontFamily.semiBold }]}>{item?.category?.name || 'News Explainer'}</Text>
                        </View>
                    </TouchableOpacityLink>
                    <ArticleItemVertical viewArticle={(catSlug, slug, postData) => this.viewArticle(catSlug, slug, postData)} viewAuthor={(slug) => this.viewAuthor(slug)} data={item} single={index == 0 ? true : false} home={true} writers={true} handleClick={(title) => this.trackStoryClick(writerData.name, 'latest story', title)} darkMode={darkMode} />
                </View>
            )
        })
        return (
            <View style={[article.headerWrapper, !helpers.isDesktop() && { position: 'relative' }, !helpers.isDesktop() && { left: 0 }, !helpers.isDesktop() && { right: 0 }, !helpers.isDesktop() && { top: -15 }]}>
                <View style={[helpers.isDesktop() && Flex.alignStart, !helpers.isDesktop() && Flex.alignCenter, Margin.mb_7]}>
                    <View style={[Padding.ph_2, Helpers.titleBgWrap, darkMode && { backgroundColor: Colors.darkBlackColorBg }]}>
                        <View style={[Helpers.borderBottom, darkMode && { borderBottomColor: Colors.lineColor }]}>
                            <Text style={[Helpers.textCenter, Typo.h3_R, helpers.isDesktop() && Typo.h3_RDesktop, Margin.mb_0, { fontSize: helpers.isDesktop() ? 30 : 20, fontFamily: FontFamily.abrilFatface, color: '#000' }, helpers.isDesktop() && { width: helpers.isWeb() ? 'fit-content' : '100%' }, darkMode && { color: Colors.lightWhite }]}>Latest Stories By Author</Text>
                        </View>
                    </View>
                    {!helpers.isDesktop() && <View style={[Helpers.titleLine, { top: 15, backgroundColor: 'rgba(102, 102, 102, 0.6)' }, darkMode && { backgroundColor: Colors.lineColor }]}></View>}
                </View>

                <View style={[helpers.isDesktop() && Padding.ph_2, !helpers.isDesktop() && Padding.ph_2, Margin.mb_2]}>
                    <InfiniteScrollComponent
                        pageStart={authorArticleData.page.current}
                        loadMore={hasNextPage ? () => this.getMoreArticles() : () => {}}
                        hasMore={authorArticleData.page.has_next || !this.state.fetchYesterdayArticles}
                        items={items}
                        darkMode={darkMode}
                    />
                </View>
            </View>
        )
    }

    renderWebPage = () => {
        return (
            this.renderPageContent()
        )
    }

    renderAppPage = () => {
        const { pageLoader, showErrorModal, footerData, writerData } = this.state;
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let redirectSlugs = {
            web: '/writers/' + this.state.writerSlug,
            native: { redirect: 'writer-single', verifyRedirect: '/writers/' + this.state.writerSlug }
        }
        return (
            <ScrollView>
                {this.renderPageContent()}
                <AppFooter navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} footerData={footerData} Breadcrumbs={Breadcrumbs} />
            </ScrollView>
        )
    }

    showPageLoader = () => {
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        return (
            <View style={[Helpers.conatinerWeb, helpers.isDesktop() && Helpers.conatinerWebFull, helpers.isDesktop() && { marginBottom: 100 }]}>
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[50]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[400]}
                    title={false}
                    pRows={5}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 60, marginTop: 0 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[50]}
                    title={false}
                    pRows={1}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <ContentLoader
                    containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                    active={true}
                    pHeight={[70]}
                    title={false}
                    pRows={3}
                    pWidth={['100%']}
                    paragraphStyles={{ marginBottom: 20, marginTop: 40 }}
                    animationDuration={400}
                    primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                    secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                />
                <View>
                    <ContentLoader
                        containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 40 }}
                        active={true}
                        pHeight={[80]}
                        title={false}
                        pRows={1}
                        pWidth={['100%']}
                        paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                        animationDuration={400}
                        primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                        secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                    />
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[30]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[32]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
                <View style={[Flex.row]}>
                    <View style={{ flex: 0.4 }}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[200]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 40, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                    <View style={[Margin.ml_2, { flex: 0.6 }]}>
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[50]}
                            title={false}
                            pRows={1}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[20]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 20, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                        <ContentLoader
                            containerStyles={{ paddingHorizontal: 0, marginTop: 0, marginBottom: 0 }}
                            active={true}
                            pHeight={[32]}
                            title={false}
                            pRows={2}
                            pWidth={['100%']}
                            paragraphStyles={{ marginBottom: 0, marginTop: 0 }}
                            animationDuration={400}
                            primaryColor={darkMode ? 'rgba(45, 48, 45,1)' : 'rgba(220, 220, 220, 1)'}
                            secondaryColor={darkMode ? 'rgba(45, 48, 45,0.8)' : 'rgba(200, 200, 200, 1)'}
                        />
                    </View>
                </View>
            </View>
        )
    }


    render() {
        const { pageLoader, showErrorModal, footerData, writerData } = this.state;
        const webView = helpers.isWeb();
        let darkMode = helpers.isDarkMode(this.props?.app?.DarkMode);
        let redirectSlugs = {
            web: '/writers/' + this.state.writerSlug,
            native: { redirect: 'writer-single', verifyRedirect: '/writers/' + this.state.writerSlug }
        }
        return (
            <View style={[webView && null, appStyles.appBg, Flex.fill, darkMode && appStyles.appBgDark]}>

                <Seo data={this.getSeoObject()} url={process.browser ? window.location.origin + window.location.pathname : ''} page="/writers/:writer_slug" pageData={writerData} />

                <AppHeader ref="header" history={this.props.history} searchQuery={this.props.searchQuery} locationHash={this.props.locationHash} navigateWeb={(slug) => this.props.navigateToScreen(slug)} navigateApp={(slug, params) => this.props.navigation.navigate(slug, params)} redirectSlugs={redirectSlugs} goBack={() => this.props.navigation.goBack()} darkMode={darkMode} showFullHeader={true} />

                {!showErrorModal && <>{pageLoader ? this.showPageLoader() :
                    (webView
                        ? this.renderWebPage()
                        : this.renderAppPage()
                    )
                }</>
                }



                {showErrorModal && <ErrorModal goToHome={() => this.props.navigation.navigate('/')} tryAgain={() => this.getModalClickEvent()} show={showErrorModal} />}
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    const { app, user } = state;
    return { app, user }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ removeUserToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(WriterSingle);

let Breadcrumbs = [];